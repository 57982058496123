import { DocumentNode } from 'graphql';
import { ExternalLink } from 'react-feather';
import { gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumbs, Section } from '../../Common/Layout';
import { assetUrl } from '../../../common/helpers';
import { useNavigation } from '../../Common/useNavigation';
import { useNotifier } from '../../../common/Notifications/NotificationProvider';
import { useParticipant } from '../ParticipantProvider';
import CharityPicker, { Charity } from '../../../common/Fundraising/CharityPicker';
import GetParticipantForDashboardQuery from '../GetParticipantForDashboardQuery';
import UI from '../../../common/UI';
import config from '../../../config';
import useMutation from '../../../api/useMutation';
import useProject from '../../useProject';
import useScroll from '../../../common/useScroll';
import useUrlState from '../../../common/useUrlState';

export const EditParticipantMutation = gql(`
  mutation EditParticipant($input: EditParticipantInput!) {
    editParticipant(input: $input) {
      id
    }
  }
`);

interface CharityPageProps {
  refetchQueries?: DocumentNode[];
}

const CharityPage = ({
  refetchQueries = [GetParticipantForDashboardQuery],
}: CharityPageProps) => {
  const { t } = useTranslation();
  const { r } = useNavigation();
  const { scrollToTop } = useScroll();
  const { participant, impersonating } = useParticipant();
  const project = useProject();
  const notifier = useNotifier();
  const history = useHistory();

  const [urlState] = useUrlState({
    parse: (params, { string }) => ({
      event: string(params.event),
      token: string(params.token),
    }),
  });

  const [charity, setCharity] = useState<Charity>(participant.charity);

  const [editParticipant, { error, loading }] = useMutation(
    EditParticipantMutation,
    {
      variables: {
        input: {
          id: participant.id,
          charity: charity ? { id: charity.id } : null,
          unset_charity: !charity,
        },
      },
      onCompleted: () => {
        notifier.success(t('information_saved'));

        if (urlState.event && urlState.token) {
          history.push(r('Registrations', {
            projectId: project.id, participantId: participant.id, eventId: urlState.event, token: urlState.token,
          }));
        } else {
          scrollToTop();
        }
      },
      refetchQueries,
      awaitRefetchQueries: true,
    },
  );

  return (
    <UI.FadeIn>
      <UI.GridContainer>
        <Breadcrumbs hideHome={impersonating}>
          <UI.Strong>{t('charity')}</UI.Strong>
        </Breadcrumbs>
        <Section title={t('link_a_charity')}>
          <UI.Form onSubmit={() => editParticipant()}>
            <UI.FormGrid>
              <UI.Div>
                <UI.Div sc={{ mb: 2 }}>
                  <UI.A
                    href={config.supporta.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={assetUrl('images/logo-supporta-mono.svg')}
                      width={80}
                      alt="Supporta"
                    />
                  </UI.A>
                </UI.Div>

                {t('link_charity_description')}
                {' '}
                <UI.A
                  href={config.supporta.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('about_supporta')}
                  {' '}
                  <UI.Icon>
                    <ExternalLink />
                  </UI.Icon>
                </UI.A>
              </UI.Div>

              <UI.ServerErrors error={error} />

              <UI.Info>
                {t('charity_applies_to_all_events_warning', { project: project.organisation_name })}
              </UI.Info>

              <CharityPicker
                charity={charity}
                onChange={(charity) => setCharity(charity)}
                showInitialCharities={false}
              />

              <UI.HR sc={{ dashed: true }} />

              <UI.Button
                type="submit"
                sc={{ brand: 'secondary', block: true }}
                disabled={loading || charity?.id === participant.charity?.id}
              >
                {t('save')}
              </UI.Button>
            </UI.FormGrid>
          </UI.Form>
        </Section>
      </UI.GridContainer>
    </UI.FadeIn>
  );
};

export default CharityPage;
