import { DocumentNode } from 'graphql';
import { useForm } from 'react-form-state-manager';
import { useTranslation } from 'react-i18next';

import { Registration } from '../helpers';
import { useParticipant } from '../ParticipantProvider';
import EditRegistrationMutation from './EditRegistrationMutation';
import GetRegistrationDetailsQuery from './GetRegistrationDetailsQuery';
import GetRegistrationSummaryForDashboardQuery from './GetRegistrationSummaryForDashboardQuery';
import UI from '../../../common/UI';
import useMutation from '../../../api/useMutation';

export interface UnassignRegistrationFormProps {
  registration: Registration;
  onSuccess?: () => void;
  refetchQueries?: DocumentNode[];
}

const UnassignRegistrationForm = ({
  registration, onSuccess,
  refetchQueries = [GetRegistrationSummaryForDashboardQuery, GetRegistrationDetailsQuery],
}: UnassignRegistrationFormProps) => {
  const { t } = useTranslation();
  const { participant } = useParticipant();

  const form = useForm({
    values: {
      id: registration.id,
      unassign: false,
    },
  });

  const [unassign, { loading }] = useMutation(
    EditRegistrationMutation,
    {
      variables: {
        input: form.values,
      },
      refetchQueries,
      awaitRefetchQueries: true,
      onCompleted: () => {
        onSuccess?.();
      },
    },
  );

  return (
    <UI.Form onSubmit={() => unassign()}>
      <UI.FormGrid sc={{ padding: [3, 4] }}>
        <UI.Div>
          <UI.Legend sc={{ fontWeight: 500, mb: 1 }}>
            {t('unassign_ticket')}
          </UI.Legend>
          {t('unassign_ticket_description')}
          {' '}
          {participant.id !== registration.participant.id && t('unassign_assignee_description', {
            assignee: registration.full_name,
          })}
        </UI.Div>

        <UI.Checkbox {...form.checkbox('unassign')}>
          {t('unassign_ticket')}
        </UI.Checkbox>

        <UI.Div>
          <UI.Button
            type="submit"
            sc={{ brand: 'secondary', loading, width: '100%' }}
            disabled={!form.values.unassign || loading}
          >
            {t('confirm')}
          </UI.Button>
        </UI.Div>
      </UI.FormGrid>
    </UI.Form>
  );
};

export default UnassignRegistrationForm;
