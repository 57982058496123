import { Download, Edit2 } from 'react-feather';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Order } from '../../helpers';
import { Section } from '../../../Common/Layout';
import { useGuard } from '../../Guard';
import EditInvoiceDetailsForm from './EditInvoiceDetailsForm';
import InvoiceDetails from '../../../../common/Invoice/InvoiceDetails';
import UI from '../../../../common/UI';
import useScroll from '../../../../common/useScroll';

export interface InvoiceDetailsSectionProps {
  order: Order;
}

const InvoiceDetailsSection = ({ order }: InvoiceDetailsSectionProps) => {
  const { t } = useTranslation();
  const { guard } = useGuard(order.participant);
  const { scrollToIfInvisible } = useScroll();

  const [showForm, setShowForm] = useState(false);

  const handleSuccess = () => {
    setShowForm(false);
    scrollToIfInvisible('InvoiceDetailsSection');
  };

  const handleCancel = () => {
    setShowForm(false);
    scrollToIfInvisible('InvoiceDetailsSection');
  };

  const invoiceDetails = {
    company_name: order.company_name,
    reference: order.reference,
    street: order.street,
    house_number: order.house_number,
    zip_code: order.zip_code,
    extra_address_line: order.extra_address_line,
    city: order.city,
    country: order.country,
    phone: order.phone,
    vat_id: order.vat_id,
  };

  const hasPassedOnFee = (order.paid_passed_on_fee + order.remaining_passed_on_fee) > 0;

  return (
    <>
      <Section spacing="lg" title={hasPassedOnFee ? t('invoices') : t('invoice')} id="InvoiceSection">
        <UI.GridContainer sc={{ gutter: 0.75 }}>
          {hasPassedOnFee && (
            <UI.Div>
              {t('multiple_invoices_description', { event: order.event.title })}
            </UI.Div>
          )}
          <UI.Div>
            <UI.AButton
              href={order.invoice_url}
              target="_blank"
              rel="noopener noreferrer"
              sc={{ outline: true, strong: true }}
            >
              <UI.Icon>
                <Download />
              </UI.Icon>
              {' '}
              {hasPassedOnFee ? t('order') : t('download_invoice')}
            </UI.AButton>
            {hasPassedOnFee && (
              <>
                {' '}
                <UI.AButton
                  href={order.fee_invoice_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  sc={{ outline: true }}
                >
                  <UI.Icon>
                    <Download />
                  </UI.Icon>
                  {' '}
                  {t('platform_fee')}
                </UI.AButton>
              </>
            )}
          </UI.Div>
        </UI.GridContainer>
        <UI.GridContainer>
          <UI.Legend>
            {t('invoice_details')}
          </UI.Legend>
          {!showForm && (
            <UI.FadeIn>
              <UI.GridContainer>
                <InvoiceDetails {...invoiceDetails} />

                <UI.HR sc={{ dashed: true }} />

                <UI.Button
                  onClick={() => guard(() => setShowForm(true))}
                  sc={{ block: true }}
                  aria-label={t('edit_invoice_details')}
                >
                  <UI.Icon>
                    <Edit2 />
                  </UI.Icon>
                  {' '}
                  {t('edit')}
                </UI.Button>
              </UI.GridContainer>
            </UI.FadeIn>
          )}
          {showForm && (
            <UI.FadeIn>
              <EditInvoiceDetailsForm
                order={order}
                onSuccess={handleSuccess}
                onCancel={handleCancel}
              />
            </UI.FadeIn>
          )}
        </UI.GridContainer>
      </Section>
    </>
  );
};

export default InvoiceDetailsSection;
