import { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

import { Container, Footer, Header, HeaderPlaceholder } from '../Common/Layout';
import { defaultSecondary } from '../../theme/variables';
import { useAuth } from '../../common/Auth/useAuth';
import { useBrandColor } from '../../common/Common/ThemeProvider';
import { useNavigation } from '../Common/useNavigation';
import LocaleProvider from '../../common/LocaleProvider';
import UI from '../../common/UI';

export const PortalLayout = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const { r } = useNavigation();
  const history = useHistory();

  useBrandColor(defaultSecondary);

  const { user } = useAuth({
    onUnauthenticated: () => {
      history.replace(r('Login', null, { withReturnUrl: true }));
    },
  });

  return (
    <LocaleProvider locale={user?.locale}>
      {user && <Header user={user} />}
      {!user && <HeaderPlaceholder />}

      <Container style={{ paddingTop: 60 }}>
        {user && children}
        {!user && <UI.PageLoader />}

        <Footer />
      </Container>
    </LocaleProvider>
  );
};

export default undefined;
