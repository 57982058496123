import * as yup from 'yup';
import { ChevronRight } from 'react-feather';
import { FormManager, useForm } from 'react-form-state-manager';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  OnCompletedLoginCallback, useLogin, useRequestLogin, useValidateUserEmail,
} from '../../common/Auth/useAuth';
import { emailRegex, getServerErrors } from '../../common/helpers';
import UI from '../../common/UI';
import config from '../../config';
import useValidation from '../../common/useValidation';

interface LoginFormData {
  email: string;
  token?: string;
}

const requestLoginSchema = yup.object().shape({
  email: yup.string().required().matches(emailRegex, { excludeEmptyString: true, name: 'email' }),
});

const loginSchema = yup.object().shape({
  pin: yup.string().required(),
});

export interface LoginFormProps {
  onCompleted?: OnCompletedLoginCallback,
  title?: string,
  description?: string,
  scopedEmail?: string,
  loginToken?: string,
}

const LoginForm = ({ onCompleted, title, description, scopedEmail, loginToken }: LoginFormProps) => {
  const form = useForm<LoginFormData>({
    values: {
      email: scopedEmail || '',
      token: loginToken,
    },
  });

  if (form.values.token) {
    return (
      <ValidateLoginForm
        loginToken={form.values.token}
        onCompleted={onCompleted}
        onCancel={() => form.delete('token')}
      />
    );
  }

  return (
    <RequestLoginForm form={form} title={title} description={description} scopedEmail={scopedEmail} />
  );
};

interface RequestLoginFormProps {
  form: FormManager<LoginFormData>;
  title?: string,
  description?: string,
  scopedEmail?: string,
}

const RequestLoginForm = ({ form, title, description, scopedEmail }: RequestLoginFormProps) => {
  const { t } = useTranslation();

  const [requestLogin, { error, loading }] = useRequestLogin(form.values.email, {
    onCompleted: (loginToken: string) => {
      form.set('token', loginToken);
    },
  });

  const { valid, errors } = useValidation({
    schema: requestLoginSchema,
    values: form.values,
    externalErrors: getServerErrors(error),
  });

  const { userExists, loading: validationLoading } = useValidateUserEmail(form.values.email, {
    skip: !valid || !!scopedEmail,
  });

  return (
    <UI.Form onSubmit={() => requestLogin()}>
      <UI.FormGrid>
        <UI.Div>
          <UI.H3 sc={{ mb: 1 }}>
            {title || t('log_in')}
          </UI.H3>
          {description || t('enter_email_to_continue')}
        </UI.Div>
        <UI.InputGroup sc={{ invalid: error && errors.email }}>
          <UI.InputLabel htmlFor="Email">
            {t('email_address')}
          </UI.InputLabel>
          <UI.DebouncedInput
            {...form.text('email')}
            inputMode="email"
            id="Email"
            placeholder={t('email_placeholder')}
            sc={{ size: 'lg' }}
          />
          <UI.ErrorMessages attribute={t('email_address')} errors={errors.email} />
        </UI.InputGroup>

        {valid && !scopedEmail && !userExists && !validationLoading && (
          <UI.Warning>
            {t('user_not_found')}
          </UI.Warning>
        )}

        <UI.Button
          type="submit"
          sc={{ brand: 'primary', block: true, size: 'lg', loading }}
          disabled={!valid || (!scopedEmail && !userExists) || loading}
        >
          <UI.Strong sc={{ mr: 1 }}>
            {t('log_in')}
          </UI.Strong>
          {' '}
          <UI.Icon>
            <ChevronRight strokeWidth={2.5} />
          </UI.Icon>
        </UI.Button>
      </UI.FormGrid>
    </UI.Form>
  );
};

interface ValidateLoginFormProps {
  onCompleted?: OnCompletedLoginCallback,
  onCancel: () => void;
  loginToken: string;
}

const ValidateLoginForm = ({ loginToken, onCompleted, onCancel }: ValidateLoginFormProps) => {
  const { t } = useTranslation();

  const form = useForm({
    values: {
      email: '',
      pin: '',
    },
  });

  const [login, { data, error, loading }] = useLogin(loginToken, form.values.pin, {
    onCompleted,
  });

  const { errors } = useValidation({
    schema: loginSchema,
    values: form.values,
    externalErrors: getServerErrors(error),
  });

  useEffect(() => {
    if (errors.login) {
      // There is something wrong with the login request, make the user start over.
      onCancel();
    }
  }, [errors.login, onCancel]);

  const [showingLoginCodeHelp, setShowingLoginHelp] = useState(false);

  useEffect(() => {
    if (form.values.pin.trim().length === config.pinLength) {
      login();
    }
  }, [form.values.pin, login]);

  return (
    <UI.Form onSubmit={() => login()}>
      <UI.FormGrid>
        <UI.Div>
          <UI.H3 sc={{ mb: 1 }}>
            {t('enter_your_login_code')}
          </UI.H3>

          <UI.Div>
            {t('login_requested')}
            {' '}
            <UI.A onClick={() => setShowingLoginHelp((show) => !show)}>
              {t('did_not_receive_code')}
            </UI.A>
          </UI.Div>
        </UI.Div>

        {showingLoginCodeHelp && (
          <UI.Info>
            {t('login_code_help')}
          </UI.Info>
        )}

        <UI.InputGroup sc={{ invalid: error && errors.pin }}>
          <UI.InputLabel htmlFor="Pin_0">
            {t('login_code')}
          </UI.InputLabel>
          <UI.PinCodeInput
            value={form.values.pin}
            onChange={(value) => form.set('pin', value)}
            length={config.pinLength}
            name="pin"
            id="Pin"
            autoFocus
            sc={{ size: 'lg' }}
          />
          <UI.ErrorMessages attribute={t('login_code')} errors={errors.pin} />
        </UI.InputGroup>

        <UI.Div>
          <UI.Button onClick={onCancel} sc={{ block: true, disabled: !!data || loading }}>
            {t('cancel')}
          </UI.Button>
        </UI.Div>
      </UI.FormGrid>
    </UI.Form>
  );
};

export default LoginForm;
