import { gql } from '__generated__';

export const ParticipantPropertiesFragment = gql(`
  fragment ParticipantProperties on Participant {
    id
    email
    first_name
    last_name
    full_name
    date_of_birth
    gender
    nationality
    street
    house_number
    zip_code
    city
    extra_address_line
    country
    phone
    emergency_phone
    access_token
    is_charity
    charity {
      id
      title
      logo_url
      description
    }
  }
`);

const GetParticipantForDashboardQuery = gql(`
  query GetParticipantForDashboard($project_id: ID!, $participant_id: ID) {
    session {
      id
      participant(participant_id: $participant_id, project_id: $project_id) {
        ...ParticipantProperties
      }
    }
    project(id: $project_id) {
      id
      organisation_name
      organisation_email
      organisation_address
      organisation_zip_code
      organisation_city
      organisation_country
      shift_vat
      locale
      locales
      timezone
      genders
    }
  }
`);

export default GetParticipantForDashboardQuery;
