import 'core-js/actual';
import 'intl-pluralrules';
import 'whatwg-fetch';

import { ApolloProvider } from '@apollo/client';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ModalProvider } from 'react-modal-hook';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import '../setup';

import { PortalLayout } from './Portal/PortalLayout';
import { Routes } from './Common/useNavigation';
import { UserProvider } from '../common/Auth/useAuth';
import { client, setHeaders } from '../api/apollo';
import { setupTranslations } from '../i18n';
import AccountPage from './Portal/AccountPage';
import ClaimRegistrationPage from './Claim/ClaimRegistrationPage';
import ClaimRegistrationsPage from './Claim/ClaimRegistrationsPage';
import CookieConsentProvider from '../common/Common/CookieConsentProvider';
import Dashboard from './Dashboard/Dashboard';
import GlobalStyle from '../common/GlobalStyle';
import GuestLayout from '../common/Common/GuestLayout';
import LoginPage from './Login/LoginPage';
import NotificationProvider from '../common/Notifications/NotificationProvider';
import ParticipantProvider from './Dashboard/ParticipantProvider';
import PortalPage from './Portal/PortalPage';
import RollbarProvider from '../common/Common/RollbarProvider';
import ThemeProvider from '../common/Common/ThemeProvider';
import UI from '../common/UI';

setupTranslations({
  namespaces: ['participant', 'frontend', 'common'],
});

setHeaders((headers) => ({
  ...headers,
  'Atleta-Guard': 'Participant',
}));

const App = () => (
  <RollbarProvider>
    <ThemeProvider options={{ scrollOffset: { sm: 60, md: 60 } }}>
      <GlobalStyle />
      <Suspense fallback={<UI.PageLoader />}>
        <CookieConsentProvider>
          <ApolloProvider client={client}>
            <BrowserRouter>
              <UserProvider>
                <NotificationProvider>
                  <ModalProvider>
                    <Switch>
                      <Route path={Routes.Login}>
                        <GuestLayout>
                          <LoginPage />
                        </GuestLayout>
                      </Route>

                      <Route path={Routes.Portal}>
                        <PortalLayout>
                          <Switch>
                            <Route path={Routes.Account}>
                              <AccountPage />
                            </Route>
                            <Route>
                              <PortalPage />
                            </Route>
                          </Switch>
                        </PortalLayout>
                      </Route>

                      <Route
                        path="/p/:projectId/claim"
                        render={({ match: { params: { projectId } } }) => (
                          <ParticipantProvider projectId={projectId}>
                            <Switch>
                              <Route
                                path={Routes.ClaimRegistration}
                                render={({ match: { params: { registrationId, claimToken, eventId } } }) => (
                                  <ClaimRegistrationPage
                                    eventId={eventId}
                                    registrationId={registrationId}
                                    claimToken={claimToken}
                                  />
                                )}
                              />

                              <Route
                                path={Routes.ClaimRegistrations}
                                render={({ match: { params: { eventId, participantId, claimToken } } }) => (
                                  <ClaimRegistrationsPage
                                    projectId={projectId}
                                    eventId={eventId}
                                    participantId={participantId}
                                    claimToken={claimToken}
                                  />
                                )}
                              />
                            </Switch>
                          </ParticipantProvider>
                        )}
                      />

                      <Route
                        path="/p/:projectId/:participantId"
                        render={({ match: { params: { projectId, participantId } } }) => (
                          <ParticipantProvider projectId={projectId} participantId={participantId}>
                            <Dashboard />
                          </ParticipantProvider>
                        )}
                      />

                      <Redirect to={Routes.Portal} />
                    </Switch>
                  </ModalProvider>
                </NotificationProvider>
              </UserProvider>
            </BrowserRouter>
          </ApolloProvider>
        </CookieConsentProvider>
      </Suspense>
    </ThemeProvider>
  </RollbarProvider>
);

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App />);
