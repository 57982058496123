import { ExternalLink } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { GetSupportaCampaignsQuery as GetSupportaCampaigns } from '__generated__/graphql';

import { NavItem, Section, SectionTitle } from '../../Common/Layout';
import { RegistrationSummary } from '../helpers';
import { assetUrl } from '../../../common/helpers';
import UI from '../../../common/UI';
import useLocale from '../../../common/useLocale';

export interface SupportaSectionProps {
  summary: RegistrationSummary;
  campaigns?: GetSupportaCampaigns['registrationSummary']['supporta_campaigns'];
}

const SupportaSection = ({ summary, campaigns }: SupportaSectionProps) => {
  const { t } = useTranslation();
  const { locale, formatCurrency } = useLocale();

  const { event } = summary;

  return (
    <UI.GridContainer sc={{ gutter: 0.75 }}>
      <UI.Div sc={{ px: [3, 4] }}>
        <SectionTitle>
          {t('your_fundraisers', { count: campaigns?.length || 1 })}
        </SectionTitle>
      </UI.Div>

      <UI.GridContainer sc={{ gutter: 0.5 }}>
        {!campaigns && (
          <Section>
            <UI.Div
              sc={{
                background: 'gray.50',
                flex: { alignItems: 'center', justifyContent: 'center' },
                loading: true,
                py: 0,
              }}
              style={{ minHeight: 72 }}
            >
              <UI.Loader sc={{ brand: 'gray.300' }} />
            </UI.Div>
          </Section>
        )}

        {campaigns?.map((campaign) => (
          <Section
            key={campaign.id}
          >
            <NavItem
              href={campaign.url}
              target="_blank"
              rel="noopener noreferrer"
              title={campaign.title}
              alignVertical="center"
              icon={<ExternalLink />}
              key={campaign.id}
            >
              <UI.GridContainer sc={{ columns: '1fr 70px', gutter: 0.5, alignVertical: 'center' }}>
                <UI.Div>
                  <UI.H4>
                    {campaign.title}
                  </UI.H4>
                  <UI.Span>
                    {t('fundraiser_summary', {
                      fundsRaised: formatCurrency(campaign.funds_raised),
                      charity: campaign.charity.title,
                    })}
                  </UI.Span>
                </UI.Div>
                <UI.Div>
                  {campaign.charity.logo_url && (
                    <img
                      src={campaign.charity.logo_url}
                      style={{ maxWidth: 70, maxHeight: 42, display: 'block', margin: '0 auto' }}
                      alt={campaign.charity.title}
                    />
                  )}
                </UI.Div>
              </UI.GridContainer>
            </NavItem>
          </Section>
        ))}

        {campaigns?.length === 0 && !event.require_fundraising && (
          <Section>
            <NavItem
              href={`${event.supporta_url}?locale=${locale}`}
              target="_blank"
              rel="noopener noreferrer"
              title={event.charities.length === 1 && !event.allow_other_charity
                ? t('create_fundraiser_for_charity_name', { charity: event.charities[0].title })
                : t('create_fundraiser_for_charity')}
              alignVertical="center"
              icon={<ExternalLink />}
            >
              <UI.FlexContainer sc={{ alignItems: 'center' }}>
                <UI.Div sc={{ my: -0.5, mr: 1 }}>
                  <img
                    src={assetUrl('images/logo-supporta-symbol.svg')}
                    width={14}
                    alt="Supporta"
                    style={{ display: 'block' }}
                  />
                </UI.Div>
                <UI.Span sc={{ strong: true }}>
                  {event.charities.length === 1 && !event.allow_other_charity
                    ? t('create_fundraiser_for_charity_name', { charity: event.charities[0].title })
                    : t('create_fundraiser_for_charity')}
                </UI.Span>
              </UI.FlexContainer>
            </NavItem>
          </Section>
        )}

        {campaigns?.length === 0 && event.require_fundraising && (
          <Section>
            <UI.Div sc={{ color: 'gray.500' }} style={{ minHeight: 72 }}>
              {t('no_fundraiser_found')}
            </UI.Div>
          </Section>
        )}
      </UI.GridContainer>
    </UI.GridContainer>
  );
};

export default SupportaSection;
