import * as yup from 'yup';
import { Fragment, useMemo } from 'react';
import { useForm } from 'react-form-state-manager';
import { useTranslation } from 'react-i18next';
import keyBy from 'lodash/keyBy';

import { EditOrderInput } from '__generated__/graphql';
import { FillRequiredFields } from '../../../Common/Layout';
import { Order } from '../../helpers';
import {
  ParticipantField, convertToParticipantFieldEntryInput, getDisabledChoices, getParticipantFieldEntrySchema,
  prefillFieldEntries,
} from '../../../../common/ParticipantFields/helpers';
import { filterPrefix, getServerErrors } from '../../../../common/helpers';
import { useNotifier } from '../../../../common/Notifications/NotificationProvider';
import EditOrderFromDashboardMutation from './EditOrderFromDashboardMutation';
import EditUpgradeForm from '../../../../common/Registrations/EditUpgradeForm';
import ParticipantFieldInput from '../../../../common/ParticipantFields/ParticipantFieldInput';
import UI from '../../../../common/UI';
import useMutation from '../../../../api/useMutation';
import useScroll from '../../../../common/useScroll';
import useValidation from '../../../../common/useValidation';

const getValues = (order: Order) => ({
  id: order.id,
  fields: prefillFieldEntries(
    order.participant_field_entries.map(convertToParticipantFieldEntryInput),
    order.participant_fields,
  ),
  stand_alone_upgrades: order.stand_alone_upgrades
    .filter((upgrade) => upgrade.product.active_product_variants.length > 0 || upgrade.participant_fields.length > 0)
    .map((upgrade) => ({
      id: upgrade.id,
      product_variant: (upgrade.product_variant || upgrade.product.active_product_variants.length > 0) ? {
        id: upgrade.product_variant?.id,
      } : null,
      fields: prefillFieldEntries(
        upgrade.participant_field_entries.map(convertToParticipantFieldEntryInput),
        upgrade.participant_fields,
      ),
    })),
});

const getEditOrderSchema = (participantFields: ParticipantField[]) => {
  const fieldEntrySchema = getParticipantFieldEntrySchema(participantFields);

  return yup.object().shape({
    fields: yup.array(fieldEntrySchema),
    stand_alone_upgrades: yup.array(
      yup.object({
        product_variant: yup.object({
          id: yup.string().ensure().required(),
        }).nullable().default(null),
        fields: yup.array(fieldEntrySchema),
      }),
    ),
  });
};

export interface EditExtraInfoFormProps {
  order: Order;
  onSuccess?: () => void;
  onCancel?: () => void;
}

const EditExtraInfoForm = ({
  order, onSuccess, onCancel,
}: EditExtraInfoFormProps) => {
  const { t } = useTranslation();
  const notifier = useNotifier();
  const { scrollTo, scrollToIfInvisible } = useScroll();

  const fields = order.participant_fields;

  const keyedFields = keyBy([
    ...order.participant_fields,
    ...order.stand_alone_upgrades.reduce(
      (fields, upgrade) => [...fields, ...upgrade.participant_fields],
      [] as ParticipantField[],
    ),
  ], 'id');

  /** Field entries that are already present on the participant */
  const existingFieldEntries = keyBy(order.participant_field_entries || [], 'id');

  const form = useForm<EditOrderInput>({
    values: getValues(order),
  });

  const [editOrder, { error, loading }] = useMutation(
    EditOrderFromDashboardMutation,
    {
      variables: {
        input: form.values,
      },
      onCompleted: () => {
        notifier.success(t('information_saved'));
        onSuccess?.();
      },
      onError: () => {
        scrollToIfInvisible('EditExtraInfoForm');
      },
    },
  );

  const editOrderSchema = useMemo(() => getEditOrderSchema(fields), [fields]);

  const { valid, errors } = useValidation({
    schema: editOrderSchema,
    values: form.values,
    externalErrors: getServerErrors(error),
  });

  const scrollToErrorFields = () => {
    scrollTo('EditExtraInfoForm');
    Object.keys(errors).forEach((key: string) => form.setTouched(key, true));
  };

  // Always enable submit when the form contains new field entries.
  const containsNewFieldEntry = form.values.fields.filter((fieldEntry) => !fieldEntry.id).length > 0;

  return (
    <UI.Form onSubmit={() => editOrder()} id="EditExtraInfoForm">
      <UI.FormGrid>
        <UI.ServerErrors error={error} />

        {form.values.fields.map((value, index) => (
          <ParticipantFieldInput
            value={value}
            onChange={(newValue) => form.set(`fields.${index}`, { ...form.values.fields[index], ...newValue })}
            onBlur={() => form.setTouched(`fields.${index}`, true)}
            touched={form.getTouched(`fields.${index}`)}
            field={keyedFields[value.participant_field.id]}
            disabledChoices={existingFieldEntries[value.id] ? getDisabledChoices(
              keyedFields[value.participant_field.id],
              existingFieldEntries[value.id],
            ) : []}
            errors={filterPrefix(errors, `fields.${index}`)}
            key={value.participant_field.id}
          />
        ))}

        {form.values.stand_alone_upgrades.map((value, index) => (
          <Fragment key={value.id}>
            {(index > 0 || form.values.fields.length > 0) && <UI.HR sc={{ dashed: true }} />}
            <EditUpgradeForm
              form={form}
              upgrade={order.stand_alone_upgrades.find(({ id }) => id === value.id)}
              formKey={`stand_alone_upgrades.${index}`}
              errors={errors}
            />
          </Fragment>
        ))}

        <UI.HR sc={{ dashed: true }} />

        {(!valid && form.changed()) && (
          <FillRequiredFields onClick={() => scrollToErrorFields()} />
        )}

        <UI.FormGrid sc={{ columns: onCancel ? '1fr 1fr' : '1fr' }}>
          {onCancel && (
            <UI.Button onClick={onCancel}>
              {t('cancel')}
            </UI.Button>
          )}
          <UI.Button
            type="submit"
            sc={{ brand: 'secondary' }}
            disabled={loading || !valid || (!form.changed() && !containsNewFieldEntry)}
          >
            {t('save')}
          </UI.Button>
        </UI.FormGrid>
      </UI.FormGrid>
    </UI.Form>
  );
};

export default EditExtraInfoForm;
