import {
  AlertTriangle, ChevronDown, Clock, CornerDownRight, Download, Edit, ExternalLink, Lock, Repeat, User as UserIcon,
  UserPlus, UserX, Users,
} from 'react-feather';
import { DocumentNode } from 'graphql';
import { startOfDay } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BasicRegistration, RegistrationSummary } from '../helpers';
import { DropdownSection, useDropdownSectionState } from '../../Common/Layout';
import { countItems } from '../../../common/helpers';
import { useNotifier } from '../../../common/Notifications/NotificationProvider';
import { useParticipant } from '../ParticipantProvider';
import EditRegistrationForm from './EditRegistrationForm';
import ExchangeInfo from './ExchangeInfo';
import GetRegistrationDetailsQuery from './GetRegistrationDetailsQuery';
import Guard from '../Guard';
import ParticipantFieldValue from '../../../common/ParticipantFields/ParticipantFieldValue';
import RegistrationAttribute from '../../../common/Registrations/RegistrationAttribute';
import ResaleForm from './ResaleForm';
import SellableLabel from '../../../common/Common/SellableLabel';
import TransferForm from './TransferForm';
import UI from '../../../common/UI';
import UnassignRegistrationForm from './UnassignRegistrationForm';
import UpgradeDetails from './UpgradeDetails';
import useDropdown from '../../../common/useDropdown';
import useLocale from '../../../common/useLocale';
import useProject from '../../useProject';
import useQuery from '../../../api/useQuery';
import useScroll from '../../../common/useScroll';
import useTimeSlotFormatter from '../../../common/Tickets/useTimeSlotFormatter';

export interface RegistrationDetailsProps {
  registration: BasicRegistration;
  summary: RegistrationSummary;
  open?: boolean;
  refetchQueries?: DocumentNode[];
}

const RegistrationDetails = ({
  registration,
  summary,
  open,
  refetchQueries,
}: RegistrationDetailsProps) => {
  const { t } = useTranslation();

  const containerId = `Registration_${registration.id}`;

  const { isOpen, toggleSection } = useDropdownSectionState({
    initialState: open,
  });

  return (
    <DropdownSection
      open={isOpen()}
      onToggle={() => toggleSection()}
      title={`${t('ticket')} #${registration.sequence}`}
      id={containerId}
      preview={(
        <RegistrationTitle registration={registration} summary={summary} expanded={isOpen()} />
      )}
      body={(
        <RegistrationBody
          registrationId={registration.id}
          summary={summary}
          containerId={containerId}
          refetchQueries={refetchQueries}
        />
      )}
    />
  );
};

// eslint-disable-next-line max-len
type Action = 'view_details' | 'edit_details' | 'download_ticket' | 'exchange_ticket' | 'assign_ticket' | 'resell_ticket' | 'unassign_ticket';

interface RegistrationBodyProps {
  registrationId: string;
  summary: RegistrationSummary;
  containerId: string;
  refetchQueries?: DocumentNode[];
}

const RegistrationBody = ({
  registrationId,
  summary,
  containerId,
  refetchQueries,
}: RegistrationBodyProps) => {
  const { t } = useTranslation();
  const { participant } = useParticipant();
  const { formatDate, parseDate } = useLocale();
  const { scrollToIfInvisible } = useScroll();
  const notifier = useNotifier();
  const project = useProject();
  const formatTimeSlot = useTimeSlotFormatter();

  const { data } = useQuery(
    GetRegistrationDetailsQuery,
    {
      variables: {
        participant: summary.participant.id,
        event: summary.event.id,
        token: summary.view_token,
        registration: registrationId,
      },
    },
  );

  const registration = data?.registrationSummary.registration;

  const [tab, setActiveTab] = useState<Action>();

  const {
    isShowingOptions,
    hideOptionsAnd,
    setIsShowingOptions,
    dropdownRef,
  } = useDropdown();

  if (!registration) {
    return (
      <UI.Div sc={{ padding: [3, 4] }}>
        <UI.PageLoader />
      </UI.Div>
    );
  }

  const participants = participant && (registration.participant.id !== participant.id || !registration.assigned)
    ? [participant]
    : [];

  const visibleUpgrades = registration.upgrades.filter((upgrade) => (
    !upgrade.product.is_ticket_fee && !upgrade.product.donation
  ));

  const candidates = participants.map((participant) => ({
    participant: {
      email: participant.email,
      first_name: participant.first_name,
      last_name: participant.last_name,
    },
    details: {
      date_of_birth: participant.date_of_birth,
      gender: participant.gender,
      nationality: participant.nationality || project.organisation_country,
      street: participant.street,
      house_number: participant.house_number,
      zip_code: participant.zip_code,
      city: participant.city,
      extra_address_line: participant.extra_address_line,
      country: participant.country || project.organisation_country,
      phone: participant.phone,
      emergency_phone: participant.emergency_phone,
    },
  }));

  const actions: Action[] = [
    'view_details',
    'edit_details',
    'download_ticket',
    'assign_ticket',
    'unassign_ticket',
    'exchange_ticket',
    'resell_ticket',
  ];

  const unavailableActions: Action[] = [];

  const isLocked = registration.locked;
  const isOwner = registration.owner.id === summary.participant.id;

  if (isLocked) {
    unavailableActions.push('edit_details');
  }

  if (!summary.event.tickets_available) {
    unavailableActions.push('download_ticket');
  }

  if (!registration.exchange_code || !isOwner) {
    unavailableActions.push('exchange_ticket');
  }

  if (!registration.is_reassignable || !isOwner) {
    unavailableActions.push('assign_ticket');
  }

  if (!registration.resale.resellable || !isOwner || !summary.event.active) {
    unavailableActions.push('resell_ticket');
  }

  if (!registration.is_unassignable || !isOwner) {
    unavailableActions.push('unassign_ticket');
  }

  const actionIcons = {
    view_details: <UI.Icons.Ticket />,
    download_ticket: <Download />,
    edit_details: <Edit />,
    assign_ticket: <UserPlus />,
    resell_ticket: <UI.Icons.Resale />,
    exchange_ticket: <Repeat />,
    unassign_ticket: <UserX />,
  };

  const activeTab = tab
    || (!registration.assigned && !unavailableActions.includes('assign_ticket') && 'assign_ticket')
    || (registration.participant.id === summary.participant.id && !registration.complete
      && !unavailableActions.includes('edit_details') && 'edit_details')
    || 'view_details';

  const openTab = (tab: typeof activeTab) => {
    setActiveTab(tab);
    scrollToIfInvisible(`Registration_${registration.id}`, { gutter: 1 });
  };

  const handleEdit = () => {
    setActiveTab('view_details');
    scrollToIfInvisible(containerId, { gutter: 1 });
    notifier.success(t('information_saved'));
  };

  const handleUnassign = () => {
    scrollToIfInvisible('UnassignedRegistrationsSection', { gutter: 1 });
    notifier.success(t('ticket_unassigned'));
  };

  const handleCancel = () => {
    setActiveTab('view_details');
  };

  const getActionTitle = (action: Action) => {
    if (action === 'assign_ticket' && registration.assigned) {
      return t('reassign_ticket');
    }

    return t(action);
  };

  const dropdownPosition = actions.findIndex((action) => action === activeTab) * 40 + 40;

  const ticketsAvailableFrom = summary.event.tickets_available_from
    ? parseDate(summary.event.tickets_available_from, { timezone: 'UTC' })
    : null;

  return (
    <>
      {actions.length > 1 && (
        <UI.Div sc={{ padding: [3, 4] }}>
          <UI.Legend sc={{ fontWeight: 500, mb: 1 }}>
            {t('actions')}
          </UI.Legend>
          <UI.DropdownOptionsContainer sc={{ block: true }} ref={dropdownRef}>
            <UI.Button
              onClick={() => setIsShowingOptions((showing) => !showing)}
              sc={{ block: true, width: '100%', textAlign: 'left', brand: 'gray.150' }}
            >
              <UI.Span sc={{ flex: { justifyContent: 'space-between', alignItems: 'center' } }}>
                <UI.Span>
                  <UI.Icon sc={{ mr: 2 }}>
                    {actionIcons[activeTab]}
                  </UI.Icon>
                  {getActionTitle(activeTab)}
                </UI.Span>
                <UI.Icon>
                  <ChevronDown />
                </UI.Icon>
              </UI.Span>
            </UI.Button>
            <UI.Dropdown
              sc={{ open: isShowingOptions, alignH: 'right', alignV: 'bottom' }}
              style={{ bottom: dropdownPosition, maxWidth: '100%' }}
            >
              {actions.map((action) => (action === 'download_ticket' && summary.event.tickets_available ? (
                <UI.AButton
                  href={registration.ticket_url}
                  target="_blank"
                  rel="noopener"
                  onClick={() => hideOptionsAnd()}
                  sc={{
                    blank: true,
                    textAlign: 'left',
                    px: 2.667 /** 16px */,
                    color: !summary.event.tickets_available ? 'gray.400' : undefined,
                  }}
                  key={action}
                >
                  <UI.Icon sc={{ mr: 2 }}>
                    {actionIcons.download_ticket}
                  </UI.Icon>
                  {getActionTitle(action)}
                </UI.AButton>
              ) : (
                <UI.Button
                  onClick={() => hideOptionsAnd(openTab(action))}
                  sc={{
                    blank: true, textAlign: 'left', strong: activeTab === action, px: 2.667, /** 16px */
                  }}
                  disabled={unavailableActions.includes(action)}
                  key={action}
                >
                  <UI.Span sc={{ flex: { justifyContent: 'space-between' } }} style={{ minWidth: 0 }}>
                    <UI.Truncate>
                      <UI.Icon sc={{ mr: 2 }}>
                        {actionIcons[action]}
                      </UI.Icon>
                      {getActionTitle(action)}
                    </UI.Truncate>
                    {unavailableActions.includes(action) && (
                      <UI.Span sc={{ ml: 1 }}>
                        <UI.Label sc={{ color: 'gray.600' }} style={{ fontSize: '0.75em', fontWeight: 400 }}>
                          {action === 'download_ticket'
                          && summary.event.enable_tickets
                          && ticketsAvailableFrom ? (
                              t('from_date', { date: formatDate(ticketsAvailableFrom, {
                                format: startOfDay(ticketsAvailableFrom).getTime() === ticketsAvailableFrom.getTime()
                                  ? 'display_date_without_year'
                                  : 'display_date_time_without_year',
                              }) })
                            ) : t('unavailable')}
                        </UI.Label>
                      </UI.Span>
                    )}
                  </UI.Span>
                </UI.Button>
              )))}
            </UI.Dropdown>
          </UI.DropdownOptionsContainer>
          {isLocked && activeTab !== 'resell_ticket' && (
            <UI.Info icon={<Lock />} sc={{ mt: [3, 4] }}>
              {t('registration_locked_info')}
            </UI.Info>
          )}
        </UI.Div>
      )}
      {activeTab === 'view_details' && (
        <UI.FadeIn sc={{ padding: [3, 4] }}>
          <UI.Delimit delimiter={<UI.HR sc={{ dashed: true }} />}>
            {(registration.time_slot || registration.ticket.upcoming_time_slots.length > 0) && (
              <UI.Div>
                <UI.Legend sc={{ fontWeight: 500 }}>
                  {t('time_slot')}
                </UI.Legend>
                {registration.time_slot && (
                  <>
                    <UI.Icon>
                      <Clock />
                    </UI.Icon>
                    {' '}
                    <UI.Delimit>
                      {formatTimeSlot(registration.time_slot)}
                      {registration.time_slot.title}
                    </UI.Delimit>
                  </>
                )}
                {!registration.time_slot && registration.ticket.upcoming_time_slots.length > 0 && (
                  <UI.Span sc={{ color: 'error' }}>
                    <UI.Icon>
                      <AlertTriangle />
                    </UI.Icon>
                    {' '}
                    {t('no_time_slot_chosen')}
                  </UI.Span>
                )}
              </UI.Div>
            )}

            {summary.event.ticket_numbers_published && registration.ticket_number && (
              <UI.Div>
                <UI.Div>
                  <UI.Legend sc={{ fontWeight: 500, mb: 1 }}>
                    {t('ticket_number')}
                  </UI.Legend>
                  <UI.Label sc={{ fontWeight: 500, fontSize: 3 }}>
                    <UI.Icon>
                      <UI.Icons.StartFinish />
                    </UI.Icon>
                    {' '}
                    {registration.ticket_number}
                  </UI.Label>
                </UI.Div>
                {(registration.start_time || registration.corral_name) && (
                  <UI.Div sc={{ mt: 1 }}>
                    <UI.Delimit>
                      {registration.corral_name}
                      {registration.start_time && formatDate(
                        parseDate(registration.start_time, { format: 'internal_time' }),
                        { format: 'display_time' },
                      )}
                    </UI.Delimit>
                  </UI.Div>
                )}
              </UI.Div>
            )}

            {(registration.ticket.allow_create_team || registration.ticket.allow_join_team) && (
              <UI.Div>
                <UI.Legend sc={{ fontWeight: 500 }}>
                  {t('team')}
                </UI.Legend>

                {registration.team && (
                  <>
                    <UI.Icon>
                      <Users />
                    </UI.Icon>
                    {' '}
                    {registration.team.title}
                  </>
                )}
                {!registration.team && (
                  <UI.Span sc={{ muted: true }}>
                    {t('no_team')}
                  </UI.Span>
                )}
              </UI.Div>
            )}

            <Guard scopedParticipant={summary.participant} showModal={false} title={t('your_personal_details')}>
              <UI.GridContainer>
                <UI.Div>
                  <UI.Legend sc={{ fontWeight: 500 }}>{`${t('email_address')}`}</UI.Legend>
                  {registration.participant.email}
                </UI.Div>

                {registration.required_attributes.map((attribute) => (
                  <RegistrationAttribute
                    registration={registration}
                    attribute={attribute}
                    showEmptyWarning
                    key={attribute}
                  />
                ))}
              </UI.GridContainer>
            </Guard>

            {registration.participant_fields.length > 0 && (
              <UI.GridContainer>
                {registration.participant_fields.map((field) => (
                  <ParticipantFieldValue
                    fieldEntry={{
                      value: null,
                      choice_entries: [],
                      field: {
                        id: field.id,
                      },
                      ...registration.participant_field_entries.filter(
                        (fieldEntry) => fieldEntry.field.id === field.id,
                      )[0],
                    }}
                    field={field}
                    key={field.id}
                  />
                ))}
              </UI.GridContainer>
            )}

            {!isOwner && (
              <UI.Div>
                <UI.Legend sc={{ fontWeight: 500 }}>
                  {t('ticket_owner')}
                </UI.Legend>
                <UI.Div>
                  <UI.Icon>
                    <UserIcon />
                  </UI.Icon>
                  {' '}
                  {registration.owner.full_name}
                </UI.Div>
              </UI.Div>
            )}

            {visibleUpgrades.length > 0 && (
              <UI.Delimit delimiter={<UI.HR sc={{ dashed: true }} />}>
                {visibleUpgrades.map((upgrade) => (
                  <UpgradeDetails upgrade={upgrade} key={upgrade.id} />
                ))}
              </UI.Delimit>
            )}

            {registration.fundraising_campaign && (
              <UI.Div>
                <UI.Legend sc={{ fontWeight: 500 }}>
                  {t('fundraising_page')}
                </UI.Legend>
                <UI.Div>
                  <UI.A
                    href={registration.fundraising_campaign.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {registration.charity?.title}
                    {' '}
                    <UI.Icon>
                      <ExternalLink />
                    </UI.Icon>
                  </UI.A>
                </UI.Div>
              </UI.Div>
            )}
          </UI.Delimit>
        </UI.FadeIn>
      )}
      {activeTab === 'edit_details' && (
        <Guard scopedParticipant={summary.participant} title={getActionTitle(activeTab)} sc={{ padding: [3, 4] }}>
          <EditRegistrationForm
            registration={registration}
            event={summary.event}
            reassignable={registration.is_reassignable && isOwner}
            candidates={registration.is_reassignable ? candidates : []}
            onSuccess={() => handleEdit()}
            onCancel={() => handleCancel()}
            refetchQueries={refetchQueries}
          />
        </Guard>
      )}
      {activeTab === 'exchange_ticket' && (
        <Guard scopedParticipant={summary.participant} title={getActionTitle(activeTab)} sc={{ padding: [3, 4] }}>
          <ExchangeInfo registration={registration} event={summary.event} />
        </Guard>
      )}
      {activeTab === 'assign_ticket' && (
        <Guard scopedParticipant={summary.participant} title={getActionTitle(activeTab)} sc={{ padding: [3, 4] }}>
          <TransferForm
            registration={registration}
            event={summary.event}
            openEditRegistrationTab={() => openTab('edit_details')}
          />
        </Guard>
      )}
      {(activeTab === 'resell_ticket') && (
        <Guard scopedParticipant={summary.participant} title={getActionTitle(activeTab)} sc={{ padding: [3, 4] }}>
          <ResaleForm registration={registration} event={summary.event} />
        </Guard>
      )}
      {activeTab === 'unassign_ticket' && (
        <Guard scopedParticipant={summary.participant} title={getActionTitle(activeTab)} sc={{ padding: [3, 4] }}>
          <UnassignRegistrationForm registration={registration} onSuccess={() => handleUnassign()} />
        </Guard>
      )}
    </>
  );
};

export interface RegistrationTitleProps {
  registration: BasicRegistration;
  summary: RegistrationSummary;
  expanded?: boolean;
}

const RegistrationTitle = ({ registration, summary, expanded }: RegistrationTitleProps) => {
  const { t } = useTranslation();
  const formatTimeSlot = useTimeSlotFormatter();

  const hasTicketNumber = summary.event.ticket_numbers_published && registration.ticket_number;

  const upgrades = countItems(registration.upgrades.filter(
    (upgrade) => !upgrade.product.is_ticket_fee && !upgrade.product.donation,
  ).map((upgrade) => (
    `${upgrade.product.title}${upgrade.product_variant ? ` (${upgrade.product_variant.title})` : ''}`
  )));

  return (
    <UI.Div>
      {registration.qr_url && (
        <UI.Div
          sc={{ textAlign: 'center' }}
          style={{
            transition: 'all 0.15s ease-in-out',
            height: expanded ? 202 : 0,
            opacity: expanded ? 1 : 0,
            overflow: 'hidden',
          }}
        >
          <UI.Div
            style={{
              padding: 6,
              border: '1px solid rgba(0, 0, 0, .15)',
              borderRadius: 6,
              maxWidth: 150,
              display: 'inline-block',
            }}
          >
            <img
              src={`${registration.qr_url}&size=300`}
              width="100%"
              height="100%"
              alt={t('qr_code')}
              style={{ display: 'block', opacity: 0.8 }}
            />
            <UI.Div sc={{ mt: 0.5, muted: true, small: true }}>
              {registration.qr_code}
            </UI.Div>
          </UI.Div>
          <UI.HR sc={{ dashed: true, my: 3 }} />
        </UI.Div>
      )}
      <UI.GridContainer sc={{ columns: expanded ? '1fr' : '1fr 46px', gutter: 0.5 }}>
        <UI.Div style={{ minWidth: 0 }}>
          <UI.H4 sc={{ color: !registration.assigned ? 'gray.500' : undefined }}>
            {registration.assigned
              ? registration.full_name
              : (registration.invited_email || t('not_assigned_yet'))}
            {!registration.assigned && registration.invited_email && ` ${t('invited_parentheses')} `}
          </UI.H4>
          <UI.FlexContainer sc={{ wrap: 'wrap' }}>
            <SellableLabel
              sc={{ mt: 0.5, mr: 1 }}
              style={{ minWidth: 0 }}
            >
              <UI.Truncate>
                <UI.Icon>
                  <UI.Icons.Ticket />
                </UI.Icon>
                {' '}
                {registration.ticket.title}
              </UI.Truncate>
            </SellableLabel>
            {registration.time_slot && (
              <UI.Label sc={{ small: true, mt: 0.5, mr: 1 }}>
                <UI.Icon>
                  <Clock />
                </UI.Icon>
                {' '}
                {formatTimeSlot(registration.time_slot)}
              </UI.Label>
            )}
            {hasTicketNumber && (
              <UI.Label sc={{ small: true, mt: 0.5 }}>
                <UI.Icon>
                  <UI.Icons.StartFinish />
                </UI.Icon>
                {' '}
                {registration.ticket_number}
              </UI.Label>
            )}
          </UI.FlexContainer>
        </UI.Div>
        {!expanded && registration.qr_url && (
          <UI.FadeIn>
            <UI.Div
              style={{
                padding: 3,
                border: '1px solid rgba(0, 0, 0, .15)',
                borderRadius: 3,
                width: 46,
                height: 46,
              }}
            >
              <img
                src={`${registration.qr_url}&size=300`}
                width="100%"
                height="100%"
                alt={t('qr_code')}
                style={{ display: 'block', opacity: 0.8 }}
              />
            </UI.Div>
          </UI.FadeIn>
        )}
      </UI.GridContainer>
      {(registration.team || upgrades.length > 0) && (
        <>
          <UI.HR sc={{ dashed: true, my: 2 }} />
          <UI.Div sc={{ color: 'gray.500', small: true }}>
            {registration.team && (
              <UI.Span sc={{ mr: 2 }}>
                <UI.Icon>
                  <Users />
                </UI.Icon>
                {' '}
                {registration.team.title}
              </UI.Span>
            )}
            {upgrades.map((upgrade) => (
              <UI.Span sc={{ mr: 2 }} key={upgrade}>
                <UI.Icon>
                  <CornerDownRight />
                </UI.Icon>
                {' '}
                {upgrade}
              </UI.Span>
            ))}
          </UI.Div>
        </>
      )}
      {summary.event.active && summary.event.enable_public_resale && registration.resale.public && (
        <UI.Info sc={{ mt: 2 }}>
          {t('ticket_for_sale_message')}
        </UI.Info>
      )}
    </UI.Div>
  );
};

export default RegistrationDetails;
