import { useState } from 'react';
import omit from 'lodash/omit';
import set from 'lodash/set';

export type TouchStatus = boolean | NestedTouchState;
export type NestedTouchState = { [key: string]: TouchStatus; };

export type TouchFunction = (key: string) => void;
export type UntouchFunction = (key: string | string[]) => void;

const useTouchState = (initialState: NestedTouchState = {}) => {
  const [touched, setTouched] = useState<NestedTouchState>(initialState);

  const touch = (key: string, value: boolean = true) => {
    if (value) {
      setTouched((touched) => ({ ...set(touched, key, true) }));
    } else {
      untouch(key);
    }
  };

  const untouch = (key: string | string[]) => {
    setTouched((touched) => ({ ...omit(touched, key) }));
  };

  return {
    touch, untouch, touched,
  };
};

export default useTouchState;
