import * as yup from 'yup';
import { Check, Copy, Share } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-form-state-manager';

import { GetResaleFormDetailsQuery as GetResaleFormDetails } from '__generated__/graphql';
import { getServerErrors } from '../../../common/helpers';
import { useCopyLink } from '../../../common/Common/CopyLink';
import { useNotifier } from '../../../common/Notifications/NotificationProvider';
import EditResaleSettingsMutation from './EditResaleSettingsMutation';
import GetResaleFormDetailsQuery from './GetResaleFormDetailsQuery';
import UI from '../../../common/UI';
import useCurrencyInput from '../../../common/useCurrencyInput';
import useLocale from '../../../common/useLocale';
import useMutation from '../../../api/useMutation';
import usePaymentMethods from '../../../common/usePaymentMethods';
import useQuery from '../../../api/useQuery';
import useScroll from '../../../common/useScroll';
import useShare from '../../../common/useShare';
import useValidation from '../../../common/useValidation';

type Registration = GetResaleFormDetails['registration'];

export interface ResaleFormProps {
  registration: {
    id: string;
  };
  event: {
    title: string;
    enable_private_resale: boolean;
    enable_public_resale: boolean;
    resale_url: string;
  };
}

const ResaleForm = ({ registration: { id }, event }: ResaleFormProps) => {
  const { data } = useQuery(
    GetResaleFormDetailsQuery,
    {
      variables: {
        id,
      },
    });

  const registration = data?.registration;

  if (!registration) {
    return <UI.PageLoader />;
  }

  return <ResaleFormBody registration={registration} event={event} />;
};

interface ResaleFormBodyProps {
  registration: Registration;
  event: {
    title: string;
    enable_private_resale: boolean;
    enable_public_resale: boolean;
    resale_url: string;
  };
}

const ResaleFormBody = ({ registration, event }: ResaleFormBodyProps) => {
  const { t } = useTranslation();
  const { formatCurrency } = useLocale();
  const { getPaymentMethodImageUrl, getPaymentMethodTitle } = usePaymentMethods();

  const showTicketPriceForm = registration.resale.min_amount !== registration.resale.max_amount;

  const inputRef = useRef<HTMLInputElement>();
  const buttonRef = useRef<HTMLButtonElement>();

  useEffect(() => {
    const input = inputRef.current;

    if (input) {
      const { paddingRight } = input.style;
      input.style.paddingRight = `${Math.max((buttonRef.current?.offsetWidth || 0) + 10, 0)}px`;

      return () => {
        input.style.paddingRight = `${paddingRight}px`;
      };
    }

    return () => {};
  });

  const { canShare, handleShare } = useShare({
    text: `${t('claim_your_ticket_for_event_here', { event: event.title })}: ${registration.resale.private_url}`,
  });

  const [copy, copied] = useCopyLink(registration.resale.private_url);

  return (
    <UI.FormGrid id={`ResaleForm_${registration.id}`} sc={{ padding: [3, 4] }}>
      {showTicketPriceForm && (
        <>
          <UI.Div>
            <UI.Legend sc={{ fontWeight: 500, mb: 1 }}>
              {t('choose_selling_price')}
            </UI.Legend>
            <Trans i18nKey="resell_ticket_description">
              <UI.A href={event.resale_url} data-atleta-embed="false" target="_blank" rel="noopener noreferer" />
            </Trans>
          </UI.Div>

          <TicketPriceForm registration={registration} />

          <UI.HR sc={{ dashed: true }} />
        </>
      )}

      <UI.Div>
        <UI.Legend sc={{ fontWeight: 500, mb: 1 }}>
          {t('verify_details')}
        </UI.Legend>
        {t('resale_verify_details_description')}
      </UI.Div>

      <UI.Info>
        <UI.Table sc={{ borderedRows: true, borderTop: true, borderWidth: 1 }}>
          <UI.THead>
            <UI.TR>
              <UI.TH colSpan={2}>
                {t('buyer_pays')}
              </UI.TH>
            </UI.TR>
          </UI.THead>
          <UI.TBody>
            <UI.TR>
              <UI.TD>
                {t('ticket_x', { ticket: registration.ticket.title })}
              </UI.TD>
              <UI.TD sc={{ textAlign: 'right', noWrap: true }}>
                {formatCurrency(registration.resale.amount)}
              </UI.TD>
            </UI.TR>
            {registration.resale.upgrades.map((upgrade) => (
              <UI.TR key={upgrade.id}>
                <UI.TD>
                  {t('upgrade_x', { upgrade: upgrade.product.title })}
                </UI.TD>
                <UI.TD sc={{ textAlign: 'right', noWrap: true }}>
                  {formatCurrency(upgrade.resale_amount)}
                </UI.TD>
              </UI.TR>
            ))}
            <UI.TR>
              <UI.TD>
                {t('platform_fee')}
              </UI.TD>
              <UI.TD sc={{ textAlign: 'right', noWrap: true }}>
                {formatCurrency(registration.resale.fee)}
              </UI.TD>
            </UI.TR>
            <UI.TR sc={{ strong: true }}>
              <UI.TD>
                {t('total')}
              </UI.TD>
              <UI.TD sc={{ textAlign: 'right', noWrap: true }}>
                {formatCurrency(registration.resale.amount + registration.resale.upgrades.reduce(
                  (sum, upgrade) => sum + upgrade.resale_amount,
                  0,
                ) + registration.resale.fee)}
              </UI.TD>
            </UI.TR>
          </UI.TBody>
        </UI.Table>

        <UI.Table
          sc={{
            borderedRows: true, borderTop: true, borderWidth: 1, mt: 2,
          }}
        >
          <UI.THead>
            <UI.TR sc={{ pt: 0 }}>
              <UI.TH colSpan={2}>
                {t('you_receive')}
              </UI.TH>
            </UI.TR>
          </UI.THead>
          <UI.TBody>
            <UI.TR>
              <UI.TD>
                {t('purchase_amount')}
              </UI.TD>
              <UI.TD sc={{ textAlign: 'right', noWrap: true }}>
                {formatCurrency(registration.resale.refund_fee + registration.resale.refund_intents.reduce(
                  (sum, refund) => sum + refund.amount,
                  0,
                ))}
              </UI.TD>
            </UI.TR>
            <UI.TR>
              <UI.TD>
                {t('deductions')}
              </UI.TD>
              <UI.TD sc={{ textAlign: 'right', noWrap: true }}>
                {formatCurrency(-registration.resale.refund_fee)}
              </UI.TD>
            </UI.TR>
            {registration.resale.refund_intents.map((refund) => (
              <UI.TR key={refund.payment.id}>
                <UI.TD>
                  <UI.Strong>
                    {t('refund')}
                  </UI.Strong>
                  {' ('}
                  {refund.payment.consumer_account && refund.payment.consumer_account}
                  {!refund.payment.consumer_account && getPaymentMethodTitle(refund.payment.payment_method)}
                  {') '}
                  <img
                    src={getPaymentMethodImageUrl(refund.payment.payment_method)}
                    alt={getPaymentMethodTitle(refund.payment.payment_method)}
                    style={{
                      width: 24, height: 16, display: 'inline-block', marginTop: 3, marginBottom: -3,
                    }}
                  />
                </UI.TD>
                <UI.TD sc={{ textAlign: 'right', noWrap: true, strong: true }}>
                  {formatCurrency(refund.amount)}
                </UI.TD>
              </UI.TR>
            ))}
            {registration.resale.refund_intents.length === 0 && (
              <UI.TR sc={{ strong: true }}>
                <UI.TD>
                  {t('refund')}
                </UI.TD>
                <UI.TD sc={{ textAlign: 'right', noWrap: true }}>
                  {formatCurrency(0)}
                </UI.TD>
              </UI.TR>
            )}
          </UI.TBody>
        </UI.Table>
      </UI.Info>

      {event.enable_private_resale && (
        <>
          <UI.HR sc={{ dashed: true }} />

          <UI.Div>
            <UI.Legend sc={{ fontWeight: 500, mb: 1 }}>
              {event.enable_public_resale ? t('private_resale') : t('sell_your_ticket')}
            </UI.Legend>
            {t('private_resale_description')}
          </UI.Div>

          <UI.InputGroup>
            <UI.InputLabel htmlFor="ResaleLink">
              {t('resale_link')}
            </UI.InputLabel>
            <UI.InputWrapper>
              <UI.Input
                defaultValue={registration.resale.private_url}
                readOnly
                onFocus={(event) => event.target.select()}
                ref={inputRef}
                sc={{ color: 'gray.500' }}
                id="ResaleLink"
              />
              <UI.Button
                onClick={() => copy()}
                sc={{ blank: true, noWrap: true }}
                ref={buttonRef}
                style={{ borderLeft: '1px solid rgba(0, 0, 0, .075)' }}
              >
                <UI.Icon>
                  {copied ? <Check /> : <Copy />}
                </UI.Icon>
                {' '}
                {t('copy')}
              </UI.Button>
            </UI.InputWrapper>
          </UI.InputGroup>

          {canShare && (
            <UI.Button onClick={handleShare} sc={{ brand: 'secondary' }}>
              <UI.Icon>
                <Share />
              </UI.Icon>
              {' '}
              {t('share_link')}
            </UI.Button>
          )}
        </>
      )}

      {event.enable_public_resale && (
        <>
          <UI.HR sc={{ dashed: true }} />

          <UI.Div>
            <UI.Legend sc={{ fontWeight: 500, mb: 1 }}>
              {event.enable_private_resale ? t('public_resale') : t('sell_your_ticket')}
            </UI.Legend>
            <Trans i18nKey="public_resale_description">
              <UI.A href={event.resale_url} data-atleta-embed="false" target="_blank" rel="noopener noreferer" />
            </Trans>
          </UI.Div>

          <PublicListingForm registration={registration} />
        </>
      )}
    </UI.FormGrid>
  );
};

interface TicketPriceFormProps {
  registration: Registration;
}

const TicketPriceForm = ({
  registration,
}: TicketPriceFormProps) => {
  const { t } = useTranslation();
  const { formatCurrency } = useLocale();
  const { scrollTo } = useScroll();
  const client = useApolloClient();
  const notifier = useNotifier();
  const currencyInput = useCurrencyInput();

  const getValues = (registration: Registration) => ({
    amount: registration.resale.amount,
  });

  const form = useForm({
    values: getValues(registration),
  });

  const [editResaleSettings, { error, loading }] = useMutation(
    EditResaleSettingsMutation,
    {
      variables: {
        input: {
          id: registration.id,
          amount: form.values.amount,
        },
      },
      onCompleted: () => {
        notifier.success(t('information_saved'));
      },
      onError: () => {
        notifier.error(t('something_went_wrong'));
        client.refetchQueries({ include: [GetResaleFormDetailsQuery] });
        scrollTo(`ResaleForm_${registration.id}`);
      },
    },
  );

  const registrationRef = useRef(registration);

  useEffect(() => {
    if (registration !== registrationRef.current) {
      form.reset(getValues(registration));
      registrationRef.current = registration;
    }
  }, [registration, form]);

  const amountRef = useRef<HTMLInputElement>();

  const setAmount = (amount: number) => {
    form.set('amount', amount);
    amountRef.current.value = currencyInput.value(amount);
  };

  const { valid, errors } = useValidation({
    schema: yup.object({
      amount: (yup as any).number().minAmount(registration.resale.min_amount).maxAmount(registration.resale.max_amount),
    }),
    values: form.values,
    externalErrors: getServerErrors(error),
  });

  return (
    <UI.Form onSubmit={() => editResaleSettings()}>
      <UI.FormGrid>
        <UI.InputGroup sc={{ valid: !errors?.amount, touched: form.getTouched('amount') }}>
          <UI.InputLabel htmlFor="Amount">
            {t('ticket_price')}
            {' '}
            <UI.RequiredMark />
          </UI.InputLabel>
          <UI.FormGrid sc={{ columns: '1fr fit-content(150px)', gutter: 0.5 }}>
            <UI.InputWrapper>
              <UI.Span sc={{ muted: !registration.resale.available }}>
                &euro;
              </UI.Span>
              <UI.DebouncedInput
                {...form.text('amount', {
                  inputHandler: {
                    parse: currencyInput.parse,
                    format: currencyInput.value,
                  },
                })}
                format={currencyInput.format}
                match={currencyInput.match}
                sc={{ pl: 4.5 }}
                ref={amountRef}
                id="Amount"
                disabled={!registration.resale.available}
              />
              <UI.ErrorMessages attribute={t('email')} errors={errors.email} />
            </UI.InputWrapper>
            <UI.Div>
              <UI.Button
                type="submit"
                sc={{ brand: 'secondary', loading }}
                disabled={loading || !valid || !form.changed()}
              >
                {t('save')}
              </UI.Button>
            </UI.Div>
          </UI.FormGrid>
          <UI.Div sc={{ mt: 1, small: true }}>
            <UI.Delimit>
              <UI.A
                onClick={() => setAmount(registration.resale.min_amount)}
                sc={{ secondary: true, disabled: !registration.resale.available }}
              >
                {t('min_amount', { amount: formatCurrency(registration.resale.min_amount) })}
              </UI.A>
              <UI.A
                onClick={() => setAmount(registration.resale.max_amount)}
                sc={{ secondary: true, disabled: !registration.resale.available }}
              >
                {t('max_amount', { amount: formatCurrency(registration.resale.max_amount) })}
              </UI.A>
            </UI.Delimit>
          </UI.Div>
          <UI.ErrorMessages attribute={t('ticket_price')} errors={errors.amount} />
        </UI.InputGroup>
      </UI.FormGrid>
    </UI.Form>
  );
};

interface PublicListingFormProps {
  registration: Registration;
}

const PublicListingForm = ({
  registration,
}: PublicListingFormProps) => {
  const { t } = useTranslation();
  const { scrollTo } = useScroll();
  const client = useApolloClient();
  const notifier = useNotifier();

  const getValues = (registration: Registration) => ({
    public: registration.resale.public,
  });

  const form = useForm({
    values: getValues(registration),
  });

  const [editResaleSettings, { error, loading }] = useMutation(
    EditResaleSettingsMutation,
    {
      variables: {
        input: {
          id: registration.id,
          public: form.values.public,
        },
      },
      onCompleted: ({ editResaleSettings: registration }) => {
        notifier.success(registration.resale.public ? t('listing_created') : t('listing_removed'));
      },
      onError: () => {
        notifier.error(t('something_went_wrong'));
        client.refetchQueries({ include: [GetResaleFormDetailsQuery] });
        scrollTo(`ResaleForm_${registration.id}`);
      },
    },
  );

  const registrationRef = useRef(registration);

  useEffect(() => {
    if (registration !== registrationRef.current) {
      form.reset(getValues(registration));
      registrationRef.current = registration;
    }
  }, [registration, form]);

  const { valid, errors } = useValidation({
    schema: yup.object(),
    values: form.values,
    externalErrors: getServerErrors(error),
  });

  return (
    <UI.Form onSubmit={() => editResaleSettings()}>
      <UI.FormGrid>
        <UI.InputGroup sc={{ invalid: errors?.public && form.touched.public }}>
          <UI.Checkbox {...form.checkbox('public')} disabled={!registration.resale.available}>
            {t('create_public_listing')}
          </UI.Checkbox>
        </UI.InputGroup>

        {form.values.public && !registration.resale.public && (
          <UI.FadeIn>
            <UI.Info>
              {t('public_resale_warning')}
            </UI.Info>
          </UI.FadeIn>
        )}

        {registration.resale.public && (
          <UI.FadeIn>
            <UI.Info>
              {t('ticket_for_sale_message')}
            </UI.Info>
          </UI.FadeIn>
        )}

        <UI.Button
          type="submit"
          sc={{ brand: 'secondary', loading }}
          disabled={loading || !valid || !form.changed()}
        >
          {!form.values.public && registration.resale.public ? t('remove_listing') : t('create_listing')}
        </UI.Button>
      </UI.FormGrid>
    </UI.Form>
  );
};

export default ResaleForm;
