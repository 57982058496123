import { AlertTriangle, CornerDownRight } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { Upgrade } from '../helpers';
import ParticipantFieldValue from '../../../common/ParticipantFields/ParticipantFieldValue';
import SellableLabel from '../../../common/Common/SellableLabel';
import UI from '../../../common/UI';

export interface UpgradeDetailsProps {
  upgrade: Upgrade;
}

const UpgradeDetails = ({ upgrade }: UpgradeDetailsProps) => {
  const { t } = useTranslation();

  return (
    <UI.GridContainer sc={{ columns: '15px 1fr' }}>
      <UI.Div>
        <UI.Icon sc={{ muted: true, mt: -0.25 }}>
          <CornerDownRight />
        </UI.Icon>
      </UI.Div>
      <UI.GridContainer>
        <UI.GridContainer sc={{ gutter: 1 / 3 }}>
          <UI.H4>
            <UI.Span sc={{ mr: 1 }}>
              <UI.Delimit>
                {upgrade.product.title}
                {upgrade.promotion.title}
              </UI.Delimit>
            </UI.Span>
            {' '}
            {upgrade.product_variant && (
              <SellableLabel type="product">
                {upgrade.product_variant.title}
              </SellableLabel>
            )}
          </UI.H4>
          {!upgrade.product_variant && upgrade.product.active_product_variants.length > 0 && (
            <UI.Span sc={{ color: 'error', noWrap: true }}>
              <UI.Icon>
                <AlertTriangle />
              </UI.Icon>
              {' '}
              {t('no_variant_chosen')}
            </UI.Span>
          )}
        </UI.GridContainer>
        {upgrade.participant_fields.map((field) => (
          <ParticipantFieldValue
            fieldEntry={{
              value: null,
              choice_entries: [],
              field: {
                id: field.id,
              },
              ...upgrade.participant_field_entries.filter(
                (fieldEntry) => fieldEntry.field.id === field.id,
              )[0],
            }}
            field={field}
            key={field.id}
          />
        ))}
      </UI.GridContainer>
    </UI.GridContainer>
  );
};

export default UpgradeDetails;
