import * as yup from 'yup';
import { DocumentNode } from 'graphql';
import { useForm } from 'react-form-state-manager';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumbs, Section } from '../Common/Layout';
import { GetCurrentUserQuery, User, useAuth, useValidateUserEmail } from '../../common/Auth/useAuth';
import { emailRegex, getServerErrors, isGraphQLAuthenticationError } from '../../common/helpers';
import { useNotifier } from '../../common/Notifications/NotificationProvider';
import EditCurrentUserMutation from '../Common/EditCurrentUserMutation';
import EmailInput from '../../common/ParticipantAttributes/Attributes/EmailInput';
import UI from '../../common/UI';
import useMutation from '../../api/useMutation';
import useScroll from '../../common/useScroll';
import useValidation from '../../common/useValidation';

const AccountPage = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <UI.FadeIn>
      <UI.GridContainer>
        <Breadcrumbs>
          <UI.Strong>{t('account')}</UI.Strong>
        </Breadcrumbs>
        <Section spacing="lg" title={t('edit_account')}>
          <AccountForm user={user} />
        </Section>
      </UI.GridContainer>
    </UI.FadeIn>
  );
};

const getEditUserSchema = (emailConfirmed: boolean) => (
  yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    email: yup.string().required().matches(emailRegex, { name: 'email' }),
    email_repeat: yup.string().test('invalid', '', () => emailConfirmed),
  })
);

const getValues = (user: User) => ({
  first_name: user.first_name,
  last_name: user.last_name,
  email: user.email,
  email_repeat: '',
});

interface AccountFormProps {
  user: User;
  refetchQueries?: DocumentNode[];
}

const AccountForm = ({ user, refetchQueries = [GetCurrentUserQuery] }: AccountFormProps) => {
  const { t } = useTranslation();
  const { scrollToTop } = useScroll();
  const notifier = useNotifier();

  const [redirecting, setRedirecting] = useState(false);

  const form = useForm({
    values: getValues(user),
  });

  // Get the form values without email repeat.
  const { email_repeat: emailRepeat, ...formValues } = form.values;

  const [editUser, { error, loading, client }] = useMutation(
    EditCurrentUserMutation,
    {
      variables: {
        input: formValues,
      },
      onCompleted: ({ editCurrentUser }) => {
        notifier.success(t('information_saved'));
        form.reset(getValues(editCurrentUser));
        scrollToTop();
      },
      onError: (error) => {
        // If the e-mail address was changed.
        if (isGraphQLAuthenticationError(error)) {
          setRedirecting(true);
          // Normal refetchQueries option does not run on error, so we must explicitly call it here
          client.refetchQueries({ include: refetchQueries });
        }

        scrollToTop();
      },
      refetchQueries,
    },
  );

  const emailChanged = form.changed('email')
    && form.values.email.toLowerCase() !== user.email.toLowerCase();
  const emailConfirmed = !emailChanged
    || form.values.email.toLowerCase() === form.values.email_repeat.toLowerCase();

  const editUserSchema = useMemo(
    () => getEditUserSchema(emailConfirmed),
    [emailConfirmed],
  );

  const { valid, errors } = useValidation({
    schema: editUserSchema,
    values: form.values,
    externalErrors: getServerErrors(error),
  });

  const { userExists, loading: validationLoading } = useValidateUserEmail(form.values.email, {
    skip: !emailChanged,
  });

  const showRepeatEmailField = emailChanged && !userExists && !errors.email;
  const showUserExistsWarning = emailChanged && !validationLoading && userExists && !errors.email;

  return (
    <UI.Form onSubmit={() => editUser()}>
      <UI.FormGrid>
        {!redirecting && <UI.ServerErrors error={error} />}

        {redirecting && (
          <UI.Info>
            {t('redirecting')}
          </UI.Info>
        )}

        <UI.InputGroup sc={{ valid: !errors.first_name, touched: form.touched.first_name }}>
          <UI.InputLabel htmlFor="UserFirstName">
            {t('first_name')}
            {' '}
            <UI.RequiredMark />
          </UI.InputLabel>
          <UI.DebouncedInput id="UserFirstName" {...form.text('first_name')} required />
          <UI.ErrorMessages attribute={t('first_name')} errors={errors.first_name} />
        </UI.InputGroup>

        <UI.InputGroup sc={{ valid: !errors.last_name, touched: form.touched.last_name }}>
          <UI.InputLabel htmlFor="UserLastName">
            {t('last_name')}
            {' '}
            <UI.RequiredMark />
          </UI.InputLabel>
          <UI.DebouncedInput id="UserLastName" {...form.text('last_name')} required />
          <UI.ErrorMessages attribute={t('last_name')} errors={errors.last_name} />
        </UI.InputGroup>

        <EmailInput
          value={form.values}
          onChange={(value) => form.set((user) => ({ ...user, ...value }))}
          onBlur={(key) => form.setTouched(key, true)}
          touched={form.touched}
          errors={errors}
          required
        />

        {showRepeatEmailField && (
          <UI.InputGroup sc={{ invalid: form.touched.email_repeat && !!errors.email_repeat }}>
            <UI.InputLabel htmlFor="UserEmailRepeat">
              {t('email_address_repeat')}
              {' '}
              <UI.RequiredMark />
            </UI.InputLabel>
            <UI.InputDescription>
              {t('change_email_description')}
            </UI.InputDescription>
            <UI.DebouncedInput
              {...form.text('email_repeat')}
              inputMode="email"
              id="UserEmailRepeat"
            />
            <UI.ErrorMessages
              attribute={t('email_repeat')}
              errors={errors.email_repeat}
            />
          </UI.InputGroup>
        )}

        <UI.HR sc={{ dashed: true }} />

        {showUserExistsWarning && (
          <UI.Warning>
            {t('edit_user_exists_warning')}
          </UI.Warning>
        )}

        <UI.Button
          type="submit"
          sc={{ brand: 'secondary', block: true }}
          disabled={loading || !valid || !form.changed()}
        >
          {t('save')}
        </UI.Button>
      </UI.FormGrid>
    </UI.Form>
  );
};

export default AccountPage;
