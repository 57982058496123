import * as yup from 'yup';
import { DocumentNode } from 'graphql';
import { useForm } from 'react-form-state-manager';
import { useTranslation } from 'react-i18next';

import { FillRequiredFields } from '../../../Common/Layout';
import { Order } from '../../helpers';
import { filterPrefix, getServerErrors } from '../../../../common/helpers';
import { useNotifier } from '../../../../common/Notifications/NotificationProvider';
import EditOrderFromDashboardMutation from './EditOrderFromDashboardMutation';
import GetParticipantForDashboardQuery from '../../GetParticipantForDashboardQuery';
import InvoiceDetailsForm, { getInvoiceSchema } from '../../../../common/Invoice/InvoiceDetailsForm';
import UI from '../../../../common/UI';
import useMutation from '../../../../api/useMutation';
import useProject from '../../../useProject';
import useScroll from '../../../../common/useScroll';
import useValidation from '../../../../common/useValidation';

export interface EditInvoiceDetailsFormProps {
  order: Order;
  onSuccess?: () => void;
  onCancel?: () => void;
  refetchQueries?: DocumentNode[];
}

const EditInvoiceDetailsForm = ({
  order, onSuccess, onCancel, refetchQueries = [GetParticipantForDashboardQuery],
}: EditInvoiceDetailsFormProps) => {
  const { t } = useTranslation();
  const project = useProject();
  const notifier = useNotifier();
  const { scrollTo, scrollToIfInvisible } = useScroll();

  const getValues = (order: Order) => ({
    invoice: {
      company_name: order.company_name,
      is_charity: order.is_charity,
      reference: order.reference,
      vat_id: order.vat_id,
      street: order.street,
      house_number: order.house_number,
      zip_code: order.zip_code,
      city: order.city,
      extra_address_line: order.extra_address_line,
      country: order.country || project.organisation_country,
      phone: order.phone,
    },
    unset_invoice_details: false,
  });

  const form = useForm({
    values: getValues(order),
  });

  const invoiceDetails = !form.values.unset_invoice_details ? {
    ...form.values.invoice,
    // Do not send VAT ID if it was not changed, so that it is not validated by the server.
    vat_id: order.vat_id_editable ? form.values.invoice.vat_id : null,
  } : null;

  const [editOrder, { error, loading }] = useMutation(
    EditOrderFromDashboardMutation,
    {
      variables: {
        input: {
          id: order.id,
          invoice: invoiceDetails,
          unset_invoice_details: form.values.unset_invoice_details,
        },
      },
      onCompleted: () => {
        notifier.success(t('information_saved'));
        onSuccess?.();
      },
      onError: () => {
        scrollToIfInvisible('EditExtraInfoForm');
      },
      refetchQueries,
      awaitRefetchQueries: refetchQueries.length > 0,
    },
  );

  const { errors, valid } = useValidation({
    schema: yup.object({
      invoice: getInvoiceSchema().nullable(),
    }),
    values: {
      invoice: invoiceDetails,
    },
    externalErrors: getServerErrors(error),
  });

  const scrollToErrorFields = () => {
    scrollTo('EditInvoiceDetailsForm');
    Object.keys(errors).forEach((key: string) => form.setTouched(key, true));
  };

  return (
    <UI.Form onSubmit={() => editOrder()} id="EditInvoiceDetailsForm">
      <UI.FormGrid>
        <UI.Legend>
          {t('edit_invoice_details')}
        </UI.Legend>

        <UI.ServerErrors error={error} />

        <UI.InputGroup>
          <UI.Checkbox
            checked={!form.values.unset_invoice_details}
            onChange={() => form.set('unset_invoice_details', (value: boolean) => !value)}
            name="specify_details"
          >
            {t('specify_invoice_details')}
          </UI.Checkbox>
        </UI.InputGroup>

        {!form.values.unset_invoice_details && (
          <InvoiceDetailsForm
            value={form.values.invoice}
            onChange={(value) => form.set((input) => ({ ...input, invoice: { ...input.invoice, ...value } }))}
            onBlur={(field) => form.setTouched(`invoice.${field}`, true)}
            touched={form.touched.invoice}
            errors={filterPrefix(errors, 'invoice')}
            disableVatId={!order.vat_id_editable}
          />
        )}

        <UI.HR sc={{ dashed: true }} />

        {(!valid && form.changed()) && (
          <FillRequiredFields onClick={() => scrollToErrorFields()} />
        )}

        <UI.FormGrid sc={{ columns: onCancel ? '1fr 1fr' : '1fr' }}>
          {onCancel && (
            <UI.Button onClick={onCancel}>
              {t('cancel')}
            </UI.Button>
          )}
          <UI.Button
            type="submit"
            sc={{ brand: 'secondary' }}
            disabled={loading || !valid || !form.changed()}
          >
            {t('save')}
          </UI.Button>
        </UI.FormGrid>
      </UI.FormGrid>
    </UI.Form>
  );
};

export default EditInvoiceDetailsForm;
