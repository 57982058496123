import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Breadcrumbs, NavItem, Section } from '../../../Common/Layout';
import { useBrandColor } from '../../../../common/Common/ThemeProvider';
import { useNavigation } from '../../../Common/useNavigation';
import { useParticipant } from '../../ParticipantProvider';
import GetRegistrationSummaryForDashboardQuery from '../GetRegistrationSummaryForDashboardQuery';
import UI from '../../../../common/UI';
import useDocumentTitle from '../../../../common/useDocumentTitle';
import useLocale from '../../../../common/useLocale';
import useProject from '../../../../common/useProject';
import useQuery from '../../../../api/useQuery';

export interface OrdersPageProps {
  participantId: string;
  eventId: string;
  token: string;
}

const OrdersPage = ({ participantId, eventId, token }: OrdersPageProps) => {
  const { t } = useTranslation();
  const { r } = useNavigation();
  const { formatCurrency, formatDate, parseDate } = useLocale();
  const { impersonating } = useParticipant();
  const project = useProject();
  const history = useHistory();

  const { data } = useQuery(
    GetRegistrationSummaryForDashboardQuery,
    {
      variables: {
        participant: participantId,
        event: eventId,
        token,
        registrations_limit: 0,
        teams_limit: 0,
        include_filtered_teams: false,
      },
      onError: (error) => {
        if (error.graphQLErrors?.length > 0) {
          // Redirect only in case of a normal authentication/validation error.
          history.replace(r('Portal'));
        }
      },
    },
  );

  const summary = data?.registrationSummary;

  useDocumentTitle([
    summary?.participant.full_name,
    summary?.event.title,
    'Atleta',
  ]);

  useBrandColor(summary?.event.brand_color);

  if (!data) {
    return <UI.PageLoader />;
  }

  return (
    <UI.FadeIn>
      <UI.GridContainer>
        <Breadcrumbs hideHome={impersonating}>
          <UI.Link to={r('Registrations', {
            eventId,
            projectId: project.id,
            participantId,
            token,
          })}
          >
            {summary.event.title}
          </UI.Link>
          <UI.Strong>{t('invoices')}</UI.Strong>
        </Breadcrumbs>
        <Section title={t('invoices')}>
          {summary.orders.map((order) => (
            <NavItem
              to={r('Order', {
                participantId,
                projectId: project.id,
                eventId,
                token,
                orderId: order.id,
              })}
              title={`${t('order')} ${order.invoice_id}`}
              key={order.id}
            >
              <UI.Div>
                <UI.H4>
                  {t('order')}
                  {' '}
                  {order.invoice_id}
                </UI.H4>
                <UI.Div>
                  <UI.Delimit>
                    {formatCurrency(order.invoice_amount)}
                    {formatDate(
                      parseDate(order.invoice_date, { timezone: 'UTC', format: 'internal_date' }),
                      { format: 'display_compact_date' },
                    )}
                  </UI.Delimit>
                </UI.Div>
                {order.remaining_amount > 0 && (
                  <UI.Warning sc={{ mt: 2 }}>
                    <UI.Strong>
                      {t('remaining')}
                      {': '}
                      {formatCurrency(order.remaining_amount)}
                    </UI.Strong>
                    {order.due_date && (
                      <>
                        {' ('}
                        {formatDate(
                          parseDate(order.due_date, {
                            timezone: 'UTC',
                            format: 'internal_date',
                          }),
                          { format: 'display_date' },
                        )}
                        )
                      </>
                    )}
                  </UI.Warning>
                )}
              </UI.Div>
            </NavItem>
          ))}
          {summary.orders.length === 0 && (
            <UI.Div sc={{ muted: true }}>
              {t('no_invoices_yet')}
            </UI.Div>
          )}
        </Section>
      </UI.GridContainer>
    </UI.FadeIn>
  );
};

export default OrdersPage;
