import { gql } from '__generated__';

gql(`
  fragment EventFields on Event {
    id
    title
    location {
      id
      title
      description
    }
    checkout_url
    website_url
    brand_color
    logo {
      id
      url
    }
    start_date
    end_date
    ticket_numbers_published
    registrations_locked
    enable_tickets
    tickets_available
    tickets_available_from
    payment_methods
    require_company_for_bank_transfer
    enable_fundraising
    require_fundraising
    allow_other_charity
    supporta_url
    charities {
      id
      title
      logo_url
      description
    }
    enable_private_resale
    enable_public_resale
    resale_url
    active
  }
`);

gql(`
  fragment BasicRegistrationFields on Registration {
    id
    qr_url
    qr_code
    sequence
    complete
    ticket_complete
    upgrades_complete
    ticket_number
    invited_email
    assigned
    locked
    has_upgrades_available
    full_name
    participant {
      id
      email
    }
    ticket {
      id
      title
    }
    promotion {
      id
      title
    }
    resale {
      id
      public
    }
    time_slot {
      id
      start_date
      start_time
      title
      multi_date
    }
    team {
      id
      title
    }
    upgrades {
      id
      complete
      product {
        id
        title
        is_ticket_fee
        donation
      }
      promotion {
        id
        title
      }
      product_variant {
        id
        title
      }
    }
  }
`);

export default gql(`
  query GetRegistrationSummaryForDashboard($participant: ID!, $event: ID!, $token: String, $registrations_limit: Int, $registration_filters: RegistrationFilters, $teams_search: String, $teams_limit: Int, $include_filtered_teams: Boolean!) {
    registrationSummary(participant: $participant, event: $event, token: $token) {
      id
      event {
        ...EventFields
      }
      view_token
      participant {
        id
        email
        full_name
        first_name
        last_name
      }
      orders {
        id
        invoice_id
        invoice_amount
        remaining_amount
        invoice_date
        due_date
      }
      has_upgrades_available
      orders_count
      registrations_count: registrations_count
      filtered_registrations_count: registrations_count(filters: $registration_filters)
      registrations: registrations(filters: $registration_filters, limit: $registrations_limit) {
        ...BasicRegistrationFields
      }
      assigned_ticket_counts {
        id
        ticket {
          id
          title
        }
        promotion {
          id
        }
        assigned_count
        total_count
      }
      teams {
        id
        title
      }
      filtered_teams: teams(search: $teams_search, limit: $teams_limit) @include (if: $include_filtered_teams) {
        id
        title
      }
      filtered_teams_count: teams_count(search: $teams_search)
      is_company
      claim_url
      export_participants_url
      tickets_url
      messages {
        id
        subject
        parsed_content(participant_id: $participant)
        publication_date
      }
    }
  }
`);
