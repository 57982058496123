import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useCallback, useEffect, useState } from 'react';
import config from '../../config';

import { isSupportedLocale } from '../../i18n';
import { isValidLocale, setWindowLocation } from '../../common/helpers';
import { useAuth } from '../../common/Auth/useAuth';
import { useNavigation } from '../Common/useNavigation';
import LoginForm from '../Common/LoginForm';
import UI from '../../common/UI';
import useDocumentTitle from '../../common/useDocumentTitle';
import useUrlState from '../../common/useUrlState';

const LoginPage = () => {
  const { t } = useTranslation();
  const { r } = useNavigation();
  const history = useHistory();

  useDocumentTitle([
    t('log_in'),
    t('dashboard'),
    'Atleta',
  ]);

  const [urlState, setUrlState] = useUrlState({
    parse: (params, { string, boolean }) => ({
      returnUrl: string(params.returnUrl),
      email: string(params.email),
      loginToken: string(params.loginToken),
      justClaimed: boolean(params.justClaimed),
      locale: isValidLocale(params.locale) && isSupportedLocale(params.locale) ? params.locale : null,
    }),
  });

  // Retrieve the login token from the URL and remember it.
  const [loginToken] = useState(urlState.loginToken);

  // Remove the login token from the URL.
  useEffect(() => {
    if (urlState.loginToken) {
      setUrlState((params) => ({
        ...params,
        loginToken: null,
      }), true);
    }
  }, [urlState.loginToken, setUrlState]);

  const redirect = useCallback(() => {
    if (urlState.returnUrl?.startsWith(config.baseUrl)) {
      setWindowLocation(urlState.returnUrl);
    } else {
      history.replace(r('Portal'));
    }
  }, [r, urlState.returnUrl, history]);

  const { user, loading } = useAuth({
    onAuthenticated: redirect,
  });

  if (loading || user) {
    return (
      <UI.PageLoader />
    );
  }

  return (
    <UI.Card sc={{ padding: [4, 6], mx: [3, 4] }}>
      {urlState.justClaimed && (
        <UI.Success sc={{ mb: 4 }}>
          {t('ticket_claimed_title')}
        </UI.Success>
      )}
      <LoginForm scopedEmail={urlState.email} loginToken={loginToken} />
    </UI.Card>
  );
};

export default LoginPage;

