import * as yup from 'yup';
import { DocumentNode } from 'graphql';
import { User, Users } from 'react-feather';
import { useForm } from 'react-form-state-manager';
import { useTranslation } from 'react-i18next';

import { DropdownSection, useDropdownSectionState } from '../../Common/Layout';
import { RegistrationSummary, Team } from '../helpers';
import { getServerErrors } from '../../../common/helpers';
import { useGuard } from '../Guard';
import { useNotifier } from '../../../common/Notifications/NotificationProvider';
import EditTeamMutation from './EditTeamMutation';
import GetRegistrationDetailsQuery from './GetRegistrationDetailsQuery';
import GetRegistrationSummaryForDashboardQuery from './GetRegistrationSummaryForDashboardQuery';
import GetTeamDetailsQuery from './GetTeamDetailsQuery';
import UI from '../../../common/UI';
import useMutation from '../../../api/useMutation';
import useQuery from '../../../api/useQuery';
import useValidation from '../../../common/useValidation';

export interface TeamDetailsProps {
  team: Team;
  summary: RegistrationSummary;
  open?: boolean;
  refetchQueries?: DocumentNode[];
}

const TeamDetails = ({
  team, summary, open, refetchQueries,
}: TeamDetailsProps) => {
  const { guard } = useGuard(summary.participant);
  const { isOpen, toggleSection } = useDropdownSectionState({ initialState: open });

  return (
    <DropdownSection
      open={isOpen()}
      onToggle={() => guard(() => toggleSection())}
      title={team.title}
      preview={(
        <UI.H4>
          <UI.Icon>
            <Users />
          </UI.Icon>
          {' '}
          {team.title}
        </UI.H4>
      )}
      body={(
        <TeamBody teamId={team.id} refetchQueries={refetchQueries} />
      )}
    />
  );
};

interface TeamBodyProps {
  teamId: string;
  refetchQueries?: DocumentNode[];
}

const TeamBody = ({ teamId, refetchQueries }: TeamBodyProps) => {
  const { t } = useTranslation();

  const { data } = useQuery(
    GetTeamDetailsQuery,
    {
      variables: {
        id: teamId,
      },
    },
  );

  const team = data?.team;

  if (!team) {
    return (
      <UI.Div sc={{ padding: [3, 4] }}>
        <UI.PageLoader />
      </UI.Div>
    );
  }

  return (
    <UI.GridContainer sc={{ padding: [3, 4] }}>
      <UI.Div>
        <UI.Strong>
          {t('team_members')}
        </UI.Strong>
        {team.members.map((member) => (
          <UI.GridContainer
            sc={{
              columns: '1fr 120px', gutter: 0.5, muted: !member.full_name,
            }}
            key={member.id}
          >
            <UI.Div>
              <UI.Icon>
                <User />
              </UI.Icon>
              {' '}
              {member.full_name || t('not_assigned_yet')}
            </UI.Div>
            <UI.Div sc={{ small: true, textAlign: 'right' }}>
              <UI.Truncate>
                <UI.Icon>
                  <UI.Icons.Ticket />
                </UI.Icon>
                {' '}
                {member.ticket.title}
              </UI.Truncate>
            </UI.Div>
          </UI.GridContainer>
        ))}
        {team.members.length === 0 && (
          <UI.Div sc={{ muted: true }}>
            {t('no_team_members_yet')}
          </UI.Div>
        )}
      </UI.Div>

      <UI.HR />

      <EditTeamForm team={team} refetchQueries={refetchQueries} />
    </UI.GridContainer>
  );
};

interface EditTeamFormProps {
  team: Team;
  refetchQueries?: DocumentNode[];
}

const EditTeamForm = ({
  team,
  refetchQueries = [GetRegistrationSummaryForDashboardQuery, GetRegistrationDetailsQuery],
}: EditTeamFormProps) => {
  const { t } = useTranslation();
  const notifier = useNotifier();

  const form = useForm({
    values: {
      title: team.title,
    },
  });

  const [editTeam, { loading: editing, error }] = useMutation(
    EditTeamMutation,
    {
      variables: {
        input: {
          id: team.id,
          title: form.values.title,
        },
      },
      onCompleted: ({ editTeam: team }) => {
        notifier.success(t('information_saved'));
        form.reset({ title: team.title });
      },
      refetchQueries,
      awaitRefetchQueries: refetchQueries.length > 0,
    },
  );

  const { errors, valid } = useValidation({
    schema: teamSchema,
    values: form.values,
    externalErrors: getServerErrors(error),
  });

  return (
    <UI.Form onSubmit={() => editTeam()}>
      <UI.FormGrid>
        <UI.ServerErrors error={error} />
        <UI.InputGroup sc={{ mb: 0, valid: !errors.title, touched: form.touched.title }}>
          <UI.InputLabel sc={{ mb: 1 }} htmlFor="TeamTitle">
            {t('team_name')}
            {' '}
            <UI.RequiredMark />
          </UI.InputLabel>
          <UI.Div>
            <UI.FormGrid sc={{ columns: '1fr fit-content(150px)', gutter: 0.5 }}>
              <UI.Div>
                <UI.DebouncedInput {...form.text('title')} id="TeamTitle" />
              </UI.Div>
              <UI.Div>
                <UI.Button
                  onClick={() => editTeam()}
                  sc={{ brand: 'secondary', loading: editing }}
                  disabled={editing || !valid || !form.changed()}
                >
                  {t('save')}
                </UI.Button>
              </UI.Div>
            </UI.FormGrid>
            <UI.ErrorMessages attribute={t('team_name')} errors={errors.title} />
          </UI.Div>
        </UI.InputGroup>
      </UI.FormGrid>
    </UI.Form>
  );
};

const teamSchema = yup.object().shape({
  title: yup.string().ensure().required(),
});

export default TeamDetails;
