import * as yup from 'yup';
import { Share } from 'react-feather';
import { useForm } from 'react-form-state-manager';
import { useTranslation } from 'react-i18next';

import { CopyLinkInput } from '../../../common/Common/CopyLink';
import { Event, Registration } from '../helpers';
import { emailRegex, getServerErrors } from '../../../common/helpers';
import { useNotifier } from '../../../common/Notifications/NotificationProvider';
import InviteParticipantMutation from './InviteParticipantMutation';
import UI from '../../../common/UI';
import useMutation from '../../../api/useMutation';
import useShare from '../../../common/useShare';
import useValidation from '../../../common/useValidation';

const inviteParticipantSchema = yup.object({
  email: yup.string().optional().matches(emailRegex, { excludeEmptyString: true, name: 'email' }),
});

export interface TransferFormProps {
  registration: Registration;
  event: Event;
  openEditRegistrationTab: () => void;
}

const TransferForm = ({ registration, event, openEditRegistrationTab }: TransferFormProps) => {
  const { t } = useTranslation();
  const notifier = useNotifier();

  const getValues = (registration: Registration) => ({
    email: registration.invited_email,
  });

  const form = useForm({
    values: getValues(registration),
  });

  const [inviteParticipant, { error, loading }] = useMutation(
    InviteParticipantMutation,
    {
      variables: {
        input: {
          id: registration.id,
          email: form.values.email,
        },
      },
      onCompleted: ({ inviteParticipant: registration }) => {
        notifier.success(t('invitation_sent'));
        form.reset(getValues(registration));
      },
      onError: () => {
        //
      },
    },
  );

  const { valid, errors } = useValidation({
    schema: inviteParticipantSchema,
    values: form.values,
    externalErrors: getServerErrors(error),
  });

  const { canShare, handleShare } = useShare({
    text: `${t('claim_your_ticket_for_event_here', { event: event.title })}: ${registration.claim_url}`,
  });

  return (
    <UI.FormGrid sc={{ padding: [3, 4] }}>
      <UI.Form onSubmit={() => inviteParticipant()}>
        <UI.FormGrid>
          <UI.Div>
            <UI.Legend sc={{ fontWeight: 500, mb: 1 }}>
              {t('invite_participant')}
            </UI.Legend>
            {t('invite_participant_description')}
          </UI.Div>
          <UI.InputGroup sc={{ valid: !errors.email, touched: form.touched.email }}>
            <UI.InputLabel htmlFor="email">
              {t('email')}
            </UI.InputLabel>
            <UI.DebouncedInput id="email" {...form.text('email')} />
            <UI.ErrorMessages attribute={t('email')} errors={errors.email} />
          </UI.InputGroup>

          <UI.Button
            type="submit"
            sc={{ brand: 'secondary', loading }}
            disabled={loading || !valid || !form.changed()}
          >
            {!form.values.email && registration.invited_email ? t('clear') : t('send_invitation')}
          </UI.Button>
        </UI.FormGrid>
      </UI.Form>

      <UI.HR sc={{ dashed: true }} />

      <UI.Div>
        <UI.Legend sc={{ fontWeight: 500, mb: 1 }}>
          {t('share_claim_link')}
        </UI.Legend>
        {t('share_claim_link_description')}
      </UI.Div>
      <UI.InputGroup>
        <CopyLinkInput value={registration.claim_url} />
      </UI.InputGroup>

      {canShare && (
        <UI.Button onClick={handleShare} sc={{ brand: 'secondary' }}>
          <UI.Icon>
            <Share />
          </UI.Icon>
          {' '}
          {t('share_link')}
        </UI.Button>
      )}

      <UI.HR sc={{ dashed: true }} />

      <UI.Div>
        <UI.Legend sc={{ fontWeight: 500, mb: 1 }}>
          {t('enter_details_manually')}
        </UI.Legend>
        {t('enter_details_manually_description')}
      </UI.Div>
      <UI.Button
        onClick={() => openEditRegistrationTab()}
        sc={{ brand: 'secondary' }}
      >
        {t('edit_details')}
      </UI.Button>
    </UI.FormGrid>
  );
};

export default TransferForm;
