import { useHistory } from 'react-router-dom';

import { useBrandColor } from '../../../../common/Common/ThemeProvider';
import { useNavigation } from '../../../Common/useNavigation';
import { useRegistrationsUrlState } from '../helpers';
import GetEventQuery from '../../../../frontend/Checkout/GetEventQuery';
import GetRegistrationSummaryForDashboardQuery from '../GetRegistrationSummaryForDashboardQuery';
import UI from '../../../../common/UI';
import UpgradesForm from './UpgradesForm';
import useDocumentTitle from '../../../../common/useDocumentTitle';
import useQuery from '../../../../api/useQuery';

export interface UpgradesPageProps {
  participantId: string;
  eventId: string;
  token: string;
}

const UpgradesPage = ({
  participantId, eventId, token,
}: UpgradesPageProps) => {
  const { r } = useNavigation();
  const history = useHistory();

  const [urlState] = useRegistrationsUrlState();

  const {
    data: summaryData, loading,
  } = useQuery(
    GetRegistrationSummaryForDashboardQuery,
    {
      variables: {
        participant: participantId,
        event: eventId,
        token,
        registrations_limit: urlState.registrations.limit,
        registration_filters: {
          assigned: true,
        },
        teams_limit: 0,
        include_filtered_teams: false,
      },
      onError: (error) => {
        if (error.graphQLErrors?.length > 0) {
          // Redirect only in case of a normal authentication/validation error.
          history.replace(r('Portal'));
        }
      },
    },
  );

  useDocumentTitle([
    summaryData?.registrationSummary.participant.full_name,
    summaryData?.registrationSummary.event.title,
    'Atleta',
  ]);

  useBrandColor(summaryData?.registrationSummary.event.brand_color);

  const { data: eventData } = useQuery(
    GetEventQuery,
    {
      variables: {
        id: eventId,
        invitationCode: null,
      },
    },
  );

  if (!summaryData || !eventData) {
    return <UI.PageLoader />;
  }

  return (
    <UI.FadeIn>
      <UpgradesForm
        summary={summaryData.registrationSummary}
        loadingSummary={loading}
        event={eventData.event}
      />
    </UI.FadeIn>
  );
};

export default UpgradesPage;
