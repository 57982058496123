import * as yup from 'yup';
import { DocumentNode } from 'graphql';
import { useForm } from 'react-form-state-manager';
import { useTranslation } from 'react-i18next';

import { EditRegistrationInput, RegistrationInput } from '__generated__/graphql';
import { Event, Registration } from '../helpers';
import { FillRequiredFields } from '../../Common/Layout';
import {
  convertToParticipantFieldEntryInput, getParticipantFieldEntrySchema, prefillFieldEntries,
} from '../../../common/ParticipantFields/helpers';
import { emailRegex, getServerErrors } from '../../../common/helpers';
import { getParticipantAttributesSchema } from '../../../common/ParticipantAttributes/helpers';
import BaseEditRegistrationForm, { getFundraisingSettings } from '../../../common/Registrations/EditRegistrationForm';
import EditRegistrationMutation from './EditRegistrationMutation';
import GetParticipantForDashboardQuery from '../GetParticipantForDashboardQuery';
import GetRegistrationDetailsQuery from './GetRegistrationDetailsQuery';
import GetRegistrationSummaryForDashboardQuery from './GetRegistrationSummaryForDashboardQuery';
import UI from '../../../common/UI';
import useMutation from '../../../api/useMutation';
import useProject from '../../useProject';
import useScroll from '../../../common/useScroll';
import useValidation from '../../../common/useValidation';

const getValues = (
  registration: Registration,
  defaultCountry?: string,
): EditRegistrationInput => ({
  id: registration.id,
  time_slot: (registration.time_slot || registration.ticket.upcoming_time_slots.length > 0) ? {
    id: registration.time_slot?.id,
  } : null,
  team: registration.team ? {
    id: registration.team.id,
    title: registration.team.title,
  } : null,
  participant: {
    email: registration.assigned ? registration.participant.email : '',
    first_name: registration.assigned ? registration.first_name : '',
    last_name: registration.assigned ? registration.last_name : '',
  },
  details: {
    street: registration.street,
    house_number: registration.house_number,
    zip_code: registration.zip_code,
    city: registration.city,
    extra_address_line: registration.extra_address_line,
    country: registration.country || defaultCountry,
    date_of_birth: registration.date_of_birth,
    gender: registration.gender,
    nationality: registration.nationality || defaultCountry,
    phone: registration.phone,
    emergency_phone: registration.emergency_phone,
  },
  fields: prefillFieldEntries(
    registration.participant_field_entries.map(convertToParticipantFieldEntryInput),
    registration.participant_fields,
  ),
  upgrades: registration.upgrades.filter(
    (upgrade) => upgrade.product.active_product_variants.length > 0 || upgrade.participant_fields.length > 0,
  ).map((upgrade) => ({
    id: upgrade.id,
    product_variant: (upgrade.product_variant || upgrade.product.active_product_variants.length > 0) ? {
      id: upgrade.product_variant?.id,
    } : null,
    fields: prefillFieldEntries(
      upgrade.participant_field_entries.map(convertToParticipantFieldEntryInput),
      upgrade.participant_fields,
    ),
  })),
  charity: registration.charity ? { id: registration.charity.id } : null,
});

const getRegistrationSchema = (
  registration: Registration,
  event: Event,
) => {
  const fields = [
    ...registration.participant_fields,
    ...registration.upgrades.reduce((fields, upgrade) => [
      ...fields, ...upgrade.participant_fields,
    ], []),
  ];

  const fieldEntrySchema = getParticipantFieldEntrySchema(fields);

  const { requireCharity } = getFundraisingSettings(registration, event);

  return yup.object({
    time_slot: yup.object({
      id: yup.string().ensure().required(),
    }).nullable().default(null),
    participant: yup.object({
      email: yup.string().required().matches(emailRegex, { name: 'email' }),
      first_name: yup.string().ensure().required(),
      last_name: yup.string().ensure().required(),
    }),
    details: getParticipantAttributesSchema(registration.editable_attributes),
    fields: yup.array(fieldEntrySchema),
    upgrades: yup.array(
      yup.object({
        product_variant: yup.object({
          id: yup.string().ensure().required(),
        }).nullable().default(null),
        fields: yup.array(fieldEntrySchema),
      }),
    ),
    charity: requireCharity ? yup.object().required() : yup.object().nullable().default(null),
  });
};

export interface EditRegistrationFormProps {
  registration: Registration;
  event: Event;
  reassignable?: boolean;
  candidates?: RegistrationInput[];
  onSuccess?: () => void;
  onCancel?: () => void;
  refetchQueries?: DocumentNode[];
}

const EditRegistrationForm = ({
  registration, event, reassignable = false, candidates = [], onSuccess, onCancel,
  refetchQueries = [
    GetParticipantForDashboardQuery,
    GetRegistrationSummaryForDashboardQuery,
    GetRegistrationDetailsQuery,
  ],
}: EditRegistrationFormProps) => {
  const { t } = useTranslation();
  const { scrollTo } = useScroll();
  const project = useProject();

  const form = useForm({
    values: getValues(registration, project.organisation_country),
  });

  const [editRegistration, { error, loading }] = (
    useMutation(
      EditRegistrationMutation,
      {
        variables: {
          input: {
            ...form.values,
            unset_team: registration.team && !form.values.team,
            unset_charity: registration.charity && !form.values.charity,
            send_notifications: true,
          },
        },
        refetchQueries,
        awaitRefetchQueries: refetchQueries?.length > 0,
        onCompleted: () => {
          onSuccess?.();
        },
        onError: () => {
          scrollTo(`EditRegistrationForm_${registration.id}`);
        },
      },
    )
  );

  const { valid, errors, updateCustomErrors, updateValidating } = useValidation({
    schema: getRegistrationSchema(registration, event),
    values: form.values,
    externalErrors: getServerErrors(error),
  });

  const scrollToErrorFields = () => {
    scrollTo(`EditRegistrationForm_${registration.id}`);
    Object.keys(errors).forEach((key: string) => form.setTouched(key, true));
  };

  return (
    <UI.Form onSubmit={() => editRegistration()} id={`EditRegistrationForm_${registration.id}`}>
      <UI.FormGrid sc={{ padding: [3, 4] }}>
        <UI.ServerErrors error={error} />

        <BaseEditRegistrationForm
          form={form}
          registration={{
            ...registration,
            is_reassignable: reassignable,
          }}
          event={event}
          candidates={candidates}
          errors={errors}
          updateCustomErrors={updateCustomErrors}
          updateValidating={updateValidating}
        />
      </UI.FormGrid>
      <UI.FormGrid
        sc={{ padding: [3, 4] }}
        style={{ borderTop: '1px solid rgba(0, 0, 0, 0.1)' }}
      >
        {(!valid && form.changed()) && (
          <FillRequiredFields onClick={() => scrollToErrorFields()} />
        )}
        <UI.GridContainer sc={{ columns: onCancel ? '1fr 1fr' : '1fr' }}>
          {onCancel && (
            <UI.Button onClick={() => onCancel()}>
              {t('cancel')}
            </UI.Button>
          )}
          <UI.Button
            type="submit"
            sc={{ brand: 'secondary', loading }}
            disabled={loading || !valid || !form.changed()}
          >
            {t('save')}
          </UI.Button>
        </UI.GridContainer>
      </UI.FormGrid>
    </UI.Form>
  );
};

export default EditRegistrationForm;
