import { Redirect, Route, Switch } from 'react-router-dom';
import { useState } from 'react';

import { Container, Footer, Header, HeaderPlaceholder } from '../Common/Layout';
import { Routes } from '../Common/useNavigation';
import { useAuth } from '../../common/Auth/useAuth';
import { useParticipant } from './ParticipantProvider';
import CharityPage from './Participant/CharityPage';
import Guard from './Guard';
import OrderPage from './Registrations/Orders/OrderPage';
import OrdersPage from './Registrations/Orders/OrdersPage';
import RegistrationsPage from './Registrations/RegistrationsPage';
import UI from '../../common/UI';
import UpgradesPage from './Registrations/Upgrades/UpgradesPage';
import useAtletaEmbed from '../../common/useAtletaEmbed';
import useProject from '../useProject';

const Dashboard = () => {
  const { user, loading: loadingUser } = useAuth();
  const { participant, impersonating, loading: loadingParticipant } = useParticipant();
  const project = useProject();

  useAtletaEmbed();

  // Wait for the participant to be retrieved if the current request is the initial page load,
  // to prevent briefly showing the currently authenticated username and then the impersonated
  // participant's name.
  const [isInitialPageLoad] = useState(!user && !participant);
  const waitingForParticipant = isInitialPageLoad && loadingParticipant;
  const impersonatedUsername = impersonating ? participant?.first_name : null;

  // Only show the username in the nav bar if this user can retrieve the current participant.
  // That is, if authenticated as participant John and viewing participant Jane's dashboard
  // with a view token, don't show John's username in the nav bar.
  const dashboardUser = (participant || loadingParticipant) ? user : null;

  return (
    <>
      {(loadingUser || waitingForParticipant) && <HeaderPlaceholder />}

      {!loadingUser && !waitingForParticipant && (
        <Header user={dashboardUser} impersonatedUsername={impersonatedUsername} />
      )}

      <Container style={{ paddingTop: 60 }}>
        {!project && (
          <UI.PageLoader />
        )}

        {project && (
          <Switch>
            <Route
              path={Routes.Upgrades}
              render={({ match: { params: { participantId, eventId, token } } }) => (
                <Guard
                  scopedParticipant={{ id: participantId }}
                  sc={{ px: [3, 4], pt: [3, 4], textAlign: 'center' }}
                >
                  <UpgradesPage
                    participantId={participantId}
                    eventId={eventId}
                    token={token}
                  />
                </Guard>
              )}
            />

            <Route
              path={Routes.Order}
              render={({ match: { params: { participantId, eventId, orderId, token } } }) => (
                <OrderPage
                  participantId={participantId}
                  eventId={eventId}
                  orderId={orderId}
                  token={token}
                />
              )}
            />

            <Route
              path={Routes.Orders}
              render={({ match: { params: { participantId, eventId, token } } }) => (
                <OrdersPage
                  participantId={participantId}
                  eventId={eventId}
                  token={token}
                />
              )}
            />

            <Route
              path={Routes.Registrations}
              render={({ match: { params: { participantId, eventId, token } } }) => (
                <RegistrationsPage
                  participantId={participantId}
                  eventId={eventId}
                  token={token}
                />
              )}
            />

            <Route
              path={Routes.Charity}
              render={({ match: { params: { participantId } } }) => (
                <Guard
                  scopedParticipant={{ id: participantId }}
                  sc={{ px: [3, 4], pt: [3, 4], textAlign: 'center' }}
                >
                  <CharityPage />
                </Guard>
              )}
            />

            <Route>
              <Redirect to={Routes.Portal} />
            </Route>
          </Switch>
        )}

        <Footer project={project} participant={participant} />
      </Container>
    </>
  );
};

export default Dashboard;
