import { useHistory } from 'react-router-dom';
import React, { ReactNode, useContext } from 'react';

import { Participant } from './helpers';
import { ProjectProvider } from '../../common/useProject';
import { getUrlParams } from '../../common/helpers';
import { useAuth } from '../../common/Auth/useAuth';
import { useNavigation } from '../Common/useNavigation';
import GetParticipantForDashboardQuery from './GetParticipantForDashboardQuery';
import LocaleProvider from '../../common/LocaleProvider';
import useQuery from '../../api/useQuery';

interface ParticipantContextValue {
  participant?: Participant | null;
  impersonating: boolean;
  loading: boolean;
  refetch: () => void;
}

export const ParticipantContext = React.createContext<ParticipantContextValue>({
  participant: null,
  impersonating: false,
  loading: true,
  refetch: () => {},
});

export interface ParticipantProviderProps {
  projectId: string;
  participantId?: string;
  delayRender?: boolean;
  children: ReactNode;
}

const ParticipantProvider = ({
  projectId,
  participantId,
  delayRender,
  children,
}: ParticipantProviderProps) => {
  const { r } = useNavigation();
  const { user } = useAuth();
  const history = useHistory();

  const { data, loading, refetch } = useQuery(
    GetParticipantForDashboardQuery,
    {
      variables: {
        project_id: projectId,
        // Don't treat 'claim' as ID when the route is /p/{projectId}/claim
        participant_id: participantId?.startsWith(projectId) ? participantId : null,
      },
      onError: (error) => {
        if (error.graphQLErrors?.length > 0) {
          // Redirect only in case of a normal authentication/validation error.
          history.replace(r('Portal'));
        }
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const participant = data?.session.participant;
  const project = data?.project;

  // User can be empty if the page is viewed with a token, in
  // which case we still want the language switcher to work.
  const locale = user?.locale || getUrlParams().locale;

  const impersonating = (!loading && !participant)
    || (user && participant && user.email.toLowerCase() !== participant.email.toLowerCase());

  return (
    <ParticipantContext.Provider value={{ participant, impersonating, loading, refetch }}>
      <ProjectProvider project={project}>
        <LocaleProvider
          locale={locale}
          localizeRequests
          timezone={project?.timezone}
        >
          {(!delayRender || !loading) && children}
        </LocaleProvider>
      </ProjectProvider>
    </ParticipantContext.Provider>
  );
};

export const useParticipant = () => useContext(ParticipantContext);

export default ParticipantProvider;
