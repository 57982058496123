import * as yup from 'yup';
import { useForm } from 'react-form-state-manager';
import { useTranslation } from 'react-i18next';

import {
  EditRegistrationInput, GetClaimableRegistrationQuery as GetClaimableRegistration,
} from '__generated__/graphql';

import { Event } from '../Dashboard/helpers';
import { FillRequiredFields } from '../Common/Layout';
import { emailRegex, getServerErrors } from '../../common/helpers';
import { getParticipantAttributesSchema } from '../../common/ParticipantAttributes/helpers';
import {
  getParticipantFieldEntrySchema, prefillFieldEntries,
} from '../../common/ParticipantFields/helpers';
import ClaimRegistrationMutation from './ClaimRegistrationMutation';
import EditRegistrationForm, { getFundraisingSettings } from '../../common/Registrations/EditRegistrationForm';
import UI from '../../common/UI';
import useMutation from '../../api/useMutation';
import useProject from '../useProject';
import useScroll from '../../common/useScroll';
import useValidation from '../../common/useValidation';

type Registration = GetClaimableRegistration['claimableRegistration']['next_registration'];

const getValues = (
  registration: Registration,
  defaultCountry?: string,
  invitedEmail?: string,
): EditRegistrationInput => ({
  id: registration.id,
  time_slot: (registration.time_slot || registration.ticket.upcoming_time_slots.length > 0) ? {
    id: registration.time_slot?.id,
  } : null,
  participant: {
    email: invitedEmail,
  },
  details: {
    country: defaultCountry,
    nationality: defaultCountry,
  },
  fields: prefillFieldEntries([], registration.participant_fields),
  upgrades: registration.upgrades.filter(
    (upgrade) => upgrade.product.active_product_variants.length > 0 || upgrade.participant_fields.length > 0,
  ).map((upgrade) => ({
    id: upgrade.id,
    product_variant: (upgrade.product_variant || upgrade.product.active_product_variants.length > 0) ? {
      id: upgrade.product_variant?.id,
    } : null,
    fields: prefillFieldEntries([], upgrade.participant_fields),
  })),
});

const getRegistrationSchema = (
  registration: Registration,
  event: Event,
) => {
  const fields = [
    ...registration.participant_fields,
    ...registration.upgrades.reduce((fields, upgrade) => [
      ...fields, ...upgrade.participant_fields,
    ], []),
  ];

  const fieldEntrySchema = getParticipantFieldEntrySchema(fields);

  const { requireCharity } = getFundraisingSettings(registration, event);

  return yup.object({
    time_slot: yup.object({
      id: yup.string().ensure().required(),
    }).nullable().default(null),
    participant: yup.object({
      email: yup.string().required().matches(emailRegex, { name: 'email' }),
      first_name: yup.string().ensure().required(),
      last_name: yup.string().ensure().required(),
    }),
    details: getParticipantAttributesSchema(registration.required_attributes),
    fields: yup.array(fieldEntrySchema),
    upgrades: yup.array(
      yup.object({
        product_variant: yup.object({
          id: yup.string().ensure().required(),
        }).nullable().default(null),
        fields: yup.array(fieldEntrySchema),
      }),
    ),
    charity: requireCharity ? yup.object().required() : yup.object().nullable().default(null),
  });
};

export interface ClaimRegistrationFormProps {
  registration: Registration;
  event: Event;
  owner: string;
  claimToken: string;
  onSuccess?: (email: string) => void;
  onCancel?: () => void;
}

const ClaimRegistrationForm = ({
  registration, event, owner, claimToken, onSuccess, onCancel,
}: ClaimRegistrationFormProps) => {
  const { t } = useTranslation();
  const { scrollTo } = useScroll();
  const project = useProject();

  const form = useForm({
    values: getValues(registration, project.organisation_country, registration.invited_email),
  });

  const [editRegistration, { error, loading }] = (
    useMutation(
      ClaimRegistrationMutation,
      {
        variables: {
          input: {
            ...form.values,
            claim_token: claimToken,
            send_notifications: true,
          },
        },
        onCompleted: (data, { variables }) => {
          onSuccess?.(variables.input.participant.email);
        },
        onError: () => {
          scrollTo(`ClaimRegistrationForm_${registration.id}`);
        },
      },
    )
  );

  const { valid, errors, updateCustomErrors, updateValidating } = useValidation({
    schema: getRegistrationSchema(registration, event),
    values: form.values,
    externalErrors: getServerErrors(error),
  });

  const scrollToErrorFields = () => {
    scrollTo(`ClaimRegistrationForm_${registration.id}`);
    Object.keys(errors).forEach((key: string) => form.setTouched(key, true));
  };

  return (
    <UI.Form onSubmit={() => editRegistration()} id={`ClaimRegistrationForm_${registration.id}`}>
      <UI.FormGrid sc={{ padding: [3, 4] }}>
        <UI.ServerErrors error={error} />

        <EditRegistrationForm
          form={form}
          registration={{
            ...registration,
            ticket: {
              ...registration.ticket,
              // Hide team selection
              allow_create_team: false,
              allow_join_team: false,
            },
            editable_attributes: [],
          }}
          event={event}
          canChangeEmail={!registration.invited_email}
          errors={errors}
          updateCustomErrors={updateCustomErrors}
          updateValidating={updateValidating}
        />
      </UI.FormGrid>
      <UI.FormGrid
        sc={{
          padding: [3, 4],
          columns: onCancel ? '1fr 1fr' : '1fr',
        }}
        style={{ borderTop: '1px solid rgba(0, 0, 0, 0.1)' }}
      >
        <UI.Div sc={{ color: 'gray.600' }}>
          {t('ticket_ownership_description', { owner })}
        </UI.Div>

        {(!valid && form.changed()) && (
          <FillRequiredFields onClick={() => scrollToErrorFields()} />
        )}

        <UI.Button
          type="submit"
          sc={{ brand: 'secondary', loading }}
          disabled={loading || !valid || !form.changed()}
        >
          {t('claim_ticket')}
        </UI.Button>
      </UI.FormGrid>
    </UI.Form>
  );
};

export default ClaimRegistrationForm;
