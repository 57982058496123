import { ChevronDown, ChevronUp, Clock, User } from 'react-feather';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import { CreateUpgradeInput, GetEventQuery as GetEvent, UpdateRegistrationInput } from '__generated__/graphql';

import {
  Availability, Quantities, calculateQuantities, calculateUpgradeAvailability,
} from '../../../../frontend/Checkout/useQuantities';
import { BasicRegistration } from '../../helpers';
import { ErrorBag, filterPrefix } from '../../../../common/helpers';
import { NestedTouchState, TouchFunction } from '../../../../common/useTouchState';
import { getVisibleProductsForTicketPromotion } from '../../../../frontend/Checkout/helpers';
import { useParticipant } from '../../ParticipantProvider';
import ProductSelector from '../../../../frontend/Checkout/Personalisation/ProductSelector';
import UI from '../../../../common/UI';
import UpgradeInfoForm from '../../../../frontend/Checkout/Personalisation/UpgradeInfoForm';
import useTimeSlotFormatter from '../../../../common/Tickets/useTimeSlotFormatter';

export interface UpgradeRegistrationFormProps extends GetEvent {
  registration: BasicRegistration;
  value: UpdateRegistrationInput;
  quantities: Quantities;
  availability: Availability;
  onChange: (registration: UpdateRegistrationInput) => void;
  touch?: TouchFunction;
  touched?: NestedTouchState;
  errors?: ErrorBag;
}

const UpgradeRegistrationForm = ({
  registration, value, event, quantities, availability, onChange, errors = {}, touch, touched,
}: UpgradeRegistrationFormProps) => {
  const { t } = useTranslation();
  const { participant } = useParticipant();
  const formatTimeSlot = useTimeSlotFormatter();

  const showToggle = registration.participant.id !== participant.id;

  const [showProducts, setShowProducts] = useState(!showToggle);

  const products = getVisibleProductsForTicketPromotion(registration.promotion, event.products_for_sale);

  const upgradeQuantities = calculateQuantities({
    registrations: [
      {
        ticket: { id: registration.ticket.id },
        purchase: { promotion: { id: registration.promotion.id } },
        upgrades: value.upgrades,
      },
    ],
    products: event.products_for_sale,
  });

  const upgradeAvailability = calculateUpgradeAvailability({
    quantities: upgradeQuantities,
    availability,
    products,
    registration,
  });

  const handleProductsChange = useCallback(
    (upgrades: CreateUpgradeInput[]) => {
      onChange({ ...value, upgrades });
    },
    [onChange, value],
  );

  const handleFieldsChange = (index: number) => (upgrade: CreateUpgradeInput) => {
    onChange({
      ...value,
      upgrades: value.upgrades.map((upgradeValue, i) => (i === index ? upgrade : upgradeValue)),
    });
  };

  return (
    <UI.FadeIn>
      <UI.FormGrid>
        <UI.GridContainer sc={{ gutter: 0.25 }}>
          <UI.Div>
            <UI.H4>
              <UI.Icon>
                <User />
              </UI.Icon>
              {' '}
              {registration.assigned && (registration.full_name)}
              {!registration.assigned && t('not_assigned_yet')}
            </UI.H4>

            <UI.Span sc={{ muted: true, mt: 0.5, mr: 2 }}>
              {`#${registration.sequence}`}
            </UI.Span>
            <UI.Span sc={{ mt: 0.5, noWrap: true, color: 'gray.500' }}>
              <UI.Icon>
                <UI.Icons.Ticket />
              </UI.Icon>
              {' '}
              <UI.Delimit>
                {registration.ticket.title}
                {registration.promotion.title}
                {registration.time_slot && (
                  <UI.Span sc={{ mr: 2 }}>
                    <UI.Icon>
                      <Clock />
                    </UI.Icon>
                    {' '}
                    <UI.Delimit>
                      {formatTimeSlot(registration.time_slot)}
                      {registration.time_slot.title}
                    </UI.Delimit>
                  </UI.Span>
                )}
              </UI.Delimit>
            </UI.Span>
          </UI.Div>
        </UI.GridContainer>

        {showProducts && (
          <UI.FadeIn>
            <ProductSelector
              event={event}
              products={products}
              upgrades={value.upgrades}
              onChange={handleProductsChange}
              quantities={upgradeQuantities}
              globalQuantities={quantities}
              availability={upgradeAvailability}
            />
          </UI.FadeIn>
        )}

        {value.upgrades.map((upgrade, index) => upgrade.fields.length > 0 && (
          <UpgradeInfoForm
            upgrade={upgrade}
            fields={event.enabled_participant_fields}
            product={products.filter((product) => (
              product.promotions_for_sale.filter(({ id }) => id === upgrade.purchase.promotion.id).length > 0
            ))[0]}
            onChange={handleFieldsChange(index)}
            onBlur={(key: string) => touch(`upgrades.${index}.${key}`)}
            errors={filterPrefix(errors, `upgrades.${index}`)}
            touched={get(touched, `upgrades.${index}`) as NestedTouchState}
            key={index}
          />
        ))}

        {showToggle && (
          <UI.Button
            onClick={() => setShowProducts((show) => !show)}
            sc={{ outline: true }}
            disabled={value.upgrades.length > 0}
          >
            <UI.Span style={{ fontWeight: 500 }}>
              {t(!showProducts ? 'add_extras' : 'hide')}
              {!showProducts && (
                <UI.Span sc={{ muted: true }}>
                  {` (${t('n_options', { count: products.length })})`}
                </UI.Span>
              )}
              {' '}
              <UI.Icon>
                {!showProducts && <ChevronDown />}
                {showProducts && <ChevronUp />}
              </UI.Icon>
            </UI.Span>
          </UI.Button>
        )}
      </UI.FormGrid>
    </UI.FadeIn>
  );
};

export default UpgradeRegistrationForm;
