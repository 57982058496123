import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useTranslation } from 'react-i18next';

import { Lock } from 'react-feather';
import { UIProps } from '../../theme/mixins';
import { useParticipant } from './ParticipantProvider';
import LoginForm from '../Common/LoginForm';
import UI from '../../common/UI';

interface ScopedParticipant {
  id: string;
  email?: string;
}

/**
 * Returns a callback that will only be executed if the currently
 * authenticated user's email matches the given email.
 */
export const useGuard = (scopedParticipant: ScopedParticipant) => {
  const { t } = useTranslation();
  const { participant, refetch } = useParticipant();

  const isAuthorized = participant?.id === scopedParticipant.id;

  const [callback, setCallback] = useState<() => void>();

  const [openModal, closeModal] = useModal(() => {
    const close = () => {
      setCallback(undefined);
      closeModal();
    };

    const onCompleted = () => {
      refetch();
      closeModal();
    };

    return (
      <UI.Modal maxWidth={500} close={close}>
        <LoginForm
          title={t('confirm_it_is_you')}
          description={t('confirm_it_is_you_description')}
          scopedEmail={scopedParticipant.email}
          onCompleted={onCompleted}
        />
      </UI.Modal>
    );
  }, [scopedParticipant, refetch]);

  /**
   * If the participant is authorized, calls the callback function.
   * Otherwise, shows a login modal and then calls the callback function.
   */
  const guard = useCallback((fn: () => void) => {
    if (!isAuthorized) {
      // Need to use arrow function, otherwise the SetStateAction will actually execute the fn.
      setCallback(() => fn);
      openModal();
    } else {
      fn();
    }
  }, [isAuthorized, openModal]);

  // Execute the callback when authorized.
  useEffect(() => {
    if (callback && isAuthorized) {
      callback();
      closeModal();
    }
  }, [callback, isAuthorized, closeModal]);

  return {
    guard,
    isAuthorized,
  };
};

export interface GuardProps {
  scopedParticipant: ScopedParticipant;
  showModal?: boolean;
  title?: ReactNode;
  sc?: UIProps;
  children?: ReactNode;
}

const Guard = ({ scopedParticipant, showModal = true, title, sc, children }: GuardProps) => {
  const { t } = useTranslation();
  const { guard, isAuthorized } = useGuard(scopedParticipant);

  const [initialIsAuthorized] = useState(isAuthorized);
  const [showChildren, setShowChildren] = useState(isAuthorized);

  useEffect(() => {
    if (!isAuthorized && showModal) {
      guard(() => setShowChildren(true));
    }
  }, [isAuthorized, showModal, guard]);

  if (!showChildren) {
    return (
      <UI.Div sc={sc}>
        {title && (
          <UI.Legend sc={{ fontWeight: 500 }}>
            {title}
          </UI.Legend>
        )}
        <UI.A role="button" onClick={() => guard(() => setShowChildren(true))}>
          <UI.Icon>
            <Lock />
          </UI.Icon>
          {' '}
          {t('log_in_to_view_this_section')}
        </UI.A>
      </UI.Div>
    );
  }

  return (
    <UI.FadeIn sc={{ when: !initialIsAuthorized }}>
      {children}
    </UI.FadeIn>
  );
};

export default Guard;
