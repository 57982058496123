import { Minus, Plus } from 'react-feather';
import { addDays, isPast } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GetPortalQuery as GetPortal } from '__generated__/graphql';

import { NavItem, Section } from '../Common/Layout';
import { resizedImageUrl } from '../../common/helpers';
import { useNavigation } from '../Common/useNavigation';
import DateRange from '../../common/Common/DateRange';
import GetPortalQuery from './GetPortalQuery';
import UI from '../../common/UI';
import useDocumentTitle from '../../common/useDocumentTitle';
import useLocale from '../../common/useLocale';
import useQuery from '../../api/useQuery';

const PortalPage = () => {
  const { data } = useQuery(GetPortalQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const user = data?.session.user;

  if (!user) {
    return (
      <UI.PageLoader />
    );
  }

  return (
    <Portal user={user} />
  );
};

type User = GetPortal['session']['user'];

interface PortalProps {
  user: User;
}

const Portal = ({ user }: PortalProps) => {
  const { t } = useTranslation();
  const { r } = useNavigation();
  const { parseDate } = useLocale();

  useDocumentTitle([
    user.full_name,
    'Atleta',
  ]);

  const isOrganizing = user.project_memberships.length > 0;

  const isPastEvent = (event: { end_date: string; }) => (
    isPast(addDays(parseDate(event.end_date, { timezone: 'UTC' }), 1))
  );
  const inactiveCount = user.registration_summaries.filter(({ event }) => isPastEvent(event)).length;
  const showPastToggle = inactiveCount > 0 && inactiveCount !== user.registration_summaries.length;

  const [showPast, setShowPast] = useState(!showPastToggle);

  return (
    <UI.FadeIn>
      <UI.GridContainer>
        <UI.Div sc={{ pt: [3, 4], px: [3, 4] }}>
          <UI.H2 sc={{ fontSize: 3, mb: 1 }}>
            {t('welcome_title', { name: user.first_name })}
          </UI.H2>
          {isOrganizing ? t('welcome_message_organizer') : t('welcome_message_participant')}
        </UI.Div>
        <UI.HR sc={{ mx: [0, 4] }} />
        {isOrganizing && (
          <Section title={t('projects')} secondary>
            {user.project_memberships.map((membership) => (
              <ProjectMembershipRow membership={membership} key={membership.id} />
            ))}
          </Section>
        )}
        <Section title={t('events')}>
          {user.registration_summaries.map((summary) => (
            (showPast || !isPastEvent(summary.event)) && (
              <RegistrationSummaryRow
                summary={summary}
                muted={isPastEvent(summary.event)}
                key={summary.id}
              />
            )
          ))}
          {showPastToggle && (
            <NavItem
              onClick={() => setShowPast((show) => !show)}
              icon={showPast ? <Minus /> : <Plus />}
              sc={{ background: 'gray.50', py: 3, color: 'link', fontWeight: 500 }}
              title={t(showPast ? 'hide_past_events' : 'show_past_events')}
            >
              {`${t(showPast ? 'hide_past_events' : 'show_past_events')} (${inactiveCount})`}
            </NavItem>
          )}
          {user.registration_summaries.length === 0 && (
            <UI.Div>
              {t('no_registrations_yet')}
            </UI.Div>
          )}
        </Section>
        <Section title={t('account')}>
          <NavItem to={r('Account')} title={t('edit_account')} alignVertical="center">
            <UI.H4>{t('edit_account')}</UI.H4>
            {user.email}
          </NavItem>
        </Section>
      </UI.GridContainer>
    </UI.FadeIn>
  );
};

interface ProjectMembershipRowProps {
  membership: User['project_memberships'][0];
}

const ProjectMembershipRow = ({ membership }: ProjectMembershipRowProps) => {
  const { t } = useTranslation();

  return (
    <NavItem
      href={`b/${membership.project.id}/dashboard`}
      title={membership.project.organisation_name}
      sc={{ py: 3 }}
      alignVertical="center"
    >
      <UI.FlexContainer sc={{ justifyContent: 'space-between' }}>
        <UI.H4>
          {membership.project.organisation_name}
        </UI.H4>
        <UI.Label sc={{ small: true }}>
          {t(`user_roles.${membership.role}`)}
        </UI.Label>
      </UI.FlexContainer>
    </NavItem>
  );
};

interface RegistrationSummaryRowProps {
  summary: User['registration_summaries'][0];
  muted: boolean;
}

const RegistrationSummaryRow = ({ summary, muted }: RegistrationSummaryRowProps) => {
  const { r } = useNavigation();

  const link = r('Registrations', {
    eventId: summary.event.id,
    projectId: summary.event.project.id,
    participantId: summary.participant.id,
    token: summary.view_token,
  });

  return (
    <NavItem
      to={link}
      title={summary.event.title}
      alignVertical="center"
      sc={{ muted }}
    >
      <UI.GridContainer sc={{ columns: '48px 1fr', gutter: 0.5 }}>
        <UI.ProfilePic
          sc={{ size: 48, background: summary.event.logo ? 'white' : undefined }}
        >
          {summary.event.logo && (
            <img
              src={resizedImageUrl(summary.event.logo.url, 300)}
              alt={summary.event.title}
            />
          )}
          {!summary.event.logo && (
            <UI.Span><UI.Span>{summary.event.title.charAt(0)}</UI.Span></UI.Span>
          )}
        </UI.ProfilePic>
        <UI.Div sc={{ pt: 1 }}>
          <UI.H4>{summary.event.title}</UI.H4>
          <DateRange start={summary.event.start_date} end={summary.event.end_date} />
        </UI.Div>
      </UI.GridContainer>
    </NavItem>
  );
};

export default PortalPage;
