import { gql } from '__generated__';

export const ResaleFormFieldsFragment = gql(`
  fragment ResaleFormFields on Registration {
    id
    ticket {
      id
      title
    }
    resale {
      id
      resellable
      available
      public
      private_url
      amount
      min_amount
      max_amount
      fee
      refund_fee
      refund_intents {
        payment {
          id
          payment_method
          consumer_account
        }
        amount
      }
      upgrades {
        id
        resale_amount
        product {
          id
          title
        }
      }
    }
  }
`);

export default gql(`
  query GetResaleFormDetails($id: ID!) {
    registration(id: $id) {
      ...ResaleFormFields
    }
  }
`);
