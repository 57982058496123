import { Edit2 } from 'react-feather';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Order } from '../../helpers';
import { Section } from '../../../Common/Layout';
import { useGuard } from '../../Guard';
import EditExtraInfoForm from './EditExtraInfoForm';
import ParticipantFieldValue from '../../../../common/ParticipantFields/ParticipantFieldValue';
import UI from '../../../../common/UI';
import useScroll from '../../../../common/useScroll';

export interface ExtraInfoSectionProps {
  order: Order;
}

const ExtraInfoSection = ({ order }: ExtraInfoSectionProps) => {
  const { t } = useTranslation();
  const { guard } = useGuard(order.participant);
  const { scrollToIfInvisible } = useScroll();

  const [showForm, setShowForm] = useState(false);

  const handleSuccess = () => {
    setShowForm(false);
    scrollToIfInvisible('ExtraInformationSection');
  };

  const handleCancel = () => {
    setShowForm(false);
    scrollToIfInvisible('ExtraInformationSection');
  };

  // Show the edit button if the order has fields, or has a stand-alone upgrade with fields or a product variant.
  const isEditable = order.editable && (
    order.participant_fields.length > 0 || order.stand_alone_upgrades.filter((upgrade) => (
      upgrade.participant_fields.length > 0 || upgrade.product.active_product_variants.length > 0
    )).length > 0
  );

  return (
    <Section spacing="lg" title={t('extra_information')} id="ExtraInformationSection">
      {!showForm && (
        <UI.FadeIn>
          <UI.GridContainer>
            {order.participant_fields.map((field) => (
              <ParticipantFieldValue
                fieldEntry={{
                  value: null,
                  choice_entries: [],
                  ...order.participant_field_entries.filter(
                    (fieldEntry) => fieldEntry.field.id === field.id,
                  )[0],
                }}
                field={field}
                key={field.id}
              />
            ))}
            {order.stand_alone_upgrades.map((upgrade, index) => (
              <Fragment key={upgrade.id}>
                {(index > 0 || order.participant_fields.length > 0) && <UI.HR sc={{ dashed: true }} />}
                <UI.Legend>
                  <UI.Delimit>
                    {upgrade.product.title}
                    {upgrade.promotion.title}
                    {upgrade.product_variant?.title}
                  </UI.Delimit>
                </UI.Legend>
                {upgrade.participant_fields.map((field) => (
                  <ParticipantFieldValue
                    fieldEntry={{
                      value: null,
                      choice_entries: [],
                      ...upgrade.participant_field_entries.filter(
                        (fieldEntry) => fieldEntry.field.id === field.id,
                      )[0],
                    }}
                    field={field}
                    key={field.id}
                  />
                ))}
              </Fragment>
            ))}
            {isEditable && (
              <>
                <UI.HR sc={{ dashed: true }} />
                <UI.Button
                  onClick={() => guard(() => setShowForm(true))}
                  sc={{ block: true }}
                  aria-label={t('edit_extra_information')}
                >
                  <UI.Icon>
                    <Edit2 />
                  </UI.Icon>
                  {' '}
                  {t('edit')}
                </UI.Button>
              </>
            )}
          </UI.GridContainer>
        </UI.FadeIn>
      )}
      {showForm && (
        <UI.FadeIn>
          <EditExtraInfoForm
            order={order}
            onSuccess={handleSuccess}
            onCancel={handleCancel}
          />
        </UI.FadeIn>
      )}
    </Section>
  );
};

export default ExtraInfoSection;
