import { gql } from '__generated__';

export default gql(`
  query GetClaimableRegistrations($participant_id: ID!, $event_id: ID!, $claim_token: String!, $claim_id: String) {
    claimableRegistrations(participant_id: $participant_id, event_id: $event_id, claim_token: $claim_token, claim_id: $claim_id) {
      owner
      registrations {
        id
        ticket {
          id
          title
        }
        ticket {
          id
          title
        }
        time_slot {
          id
          start_date
          start_time
          title
          multi_date
        }
        promotion {
          id
          title
        }
        team {
          id
          title
        }
        upgrades {
          id
          product {
            id
            title
            is_ticket_fee
          }
          promotion {
            id
            title
          }
          product_variant {
            id
            title
          }
        }
        available
        next_registration {
          id
        }
        claim_token
      }
      event {
        ...EventFields
      }
      charity {
        id
        title
        logo_url
        description
      }
    }
  }
`);
