import { gql } from '__generated__';

export default gql(`
  query GetPortal {
    session {
      id
      user {
        id
        first_name
        full_name
        email
        locale
        registration_summaries {
          id
          view_token
          participant {
            id
          }
          event {
            id
            title
            start_date
            end_date
            project {
              id
            }
            logo {
              id
              url
            }
          }
        }
        project_memberships {
          id
          role
          project {
            id
            organisation_name
          }
        }
      }
    }
  }
`);
