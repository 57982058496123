import { gql } from '__generated__';

export const OrderFieldsFragment = gql(`
  fragment OrderFields on Order {
    id
    event {
      id
      title
      start_date
      end_date
      payment_methods
      require_company_for_bank_transfer
      brand_color
    }
    invoice_id
    invoice_amount
    paid_passed_on_fee
    remaining_passed_on_fee
    credited_amount
    remaining_amount
    invoice_url
    fee_invoice_url
    dashboard_url
    invoice_date
    due_date
    company_name
    is_charity
    reference
    token
    street
    house_number
    zip_code
    city
    extra_address_line
    country
    phone
    vat_id
    vat_id_editable
    editable
    participant {
      id
      email
      full_name
    }
    participant_fields {
      ...ParticipantFieldFields
    }
    participant_field_entries {
      ...ParticipantFieldEntryFields
    }
    stand_alone_upgrades {
      ...UpgradeFields
    }
    summary(include_inactive: true) {
      id
      tickets {
        quantity
        purchase {
          id
          amount
          coupon_code {
            id
            value
            coupon {
              id
              type
              value
            }
          }
          promotion {
            id
            title
          }
          ticket {
            id
            title
          }
          time_slot {
            id
            start_date
            start_time
            multi_date
            title
          }
        }
      }
      products {
        quantity
        purchase {
          id
          amount
          coupon_code {
            id
            value
            coupon {
              id
              type
              value
            }
          }
          promotion {
            id
            title
          }
          product {
            id
            title
            is_ticket_fee
          }
          product_variant {
            id
            title
          }
        }
      }
    }
  }
`);

export default gql(`
  query GetOrderForDashboard($order_id: ID!, $token: String) {
    order(id: $order_id, token: $token) {
      ...OrderFields
    }
  }
`);
