import * as yup from 'yup';
import { ChevronDown, Lock } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';

import {
  AddOrderStatus, CreateUpgradeInput, InvoiceDetailsInput, ParticipantFieldEntryInput, PaymentMethodInput,
  UpdateRegistrationInput,
} from '__generated__/graphql';
import { Breadcrumbs, FillRequiredFields, NavItem, Section, SectionTitle } from '../../../Common/Layout';
import {
  Event, ParticipantField, getStandAloneProducts, getVisibleProducts,
} from '../../../../frontend/Checkout/helpers';
import { RegistrationSummary } from '../../helpers';
import { Update, containsKeyPrefix, filterPrefix, getServerErrors } from '../../../../common/helpers';
import { calculateAvailability, calculateQuantities } from '../../../../frontend/Checkout/useQuantities';
import {
  getOrderFields,
  getParticipantFieldEntrySchema, getPromotionFields, prefillFieldEntries,
} from '../../../../common/ParticipantFields/helpers';
import { useNavigation } from '../../../Common/useNavigation';
import { useParticipant } from '../../ParticipantProvider';
import { useRegistrationsUrlState } from '../helpers';
import CheckoutSummary from '../../../../frontend/Checkout/CheckoutSummary';
import CouponInput from '../../../../frontend/Checkout/Payment/CouponInput';
import InvoiceDetailsForm, {
  emptyInvoiceDetails, getInvoiceSchema,
} from '../../../../common/Invoice/InvoiceDetailsForm';
import ParticipantFieldInput from '../../../../common/ParticipantFields/ParticipantFieldInput';
import PaymentMethodSelection from '../../../../common/Payments/PaymentMethodSelection';
import ProductSelector from '../../../../frontend/Checkout/Personalisation/ProductSelector';
import UI from '../../../../common/UI';
import UpdateRegistrationsMutation from './UpdateRegistrationsMutation';
import UpgradeInfoForm from '../../../../frontend/Checkout/Personalisation/UpgradeInfoForm';
import UpgradeRegistrationForm from './UpgradeRegistrationForm';
import config from '../../../../config';
import useCheckoutSummary from '../../../../common/useCheckoutSummary';
import useLocale from '../../../../common/useLocale';
import useMutation from '../../../../api/useMutation';
import usePaymentMethods from '../../../../common/usePaymentMethods';
import useProject from '../../../useProject';
import useScroll from '../../../../common/useScroll';
import useTouchState, { NestedTouchState } from '../../../../common/useTouchState';
import useValidation from '../../../../common/useValidation';

export interface UpgradesFormProps {
  summary: RegistrationSummary;
  loadingSummary?: boolean;
  event: Event;
  perPage?: number;
}

interface UpgradesFormState {
  registrations: {
    id: string;
    upgrades: CreateUpgradeInput[];
  }[],
  stand_alone_upgrades: CreateUpgradeInput[];
  invoice: InvoiceDetailsInput;
  payment_method: Partial<PaymentMethodInput>;
  coupon: string | null;
  fields: ParticipantFieldEntryInput[];
}

const UpgradesForm = ({ summary, loadingSummary = false, event, perPage = 10 }: UpgradesFormProps) => {
  const { t } = useTranslation();
  const { r } = useNavigation();
  const { scrollTo, scrollToTop } = useScroll();
  const { participant, impersonating } = useParticipant();
  const { locale } = useLocale();
  const project = useProject();

  const { registrations } = summary;

  const getRegistration = (registrationId: string) => registrations.filter((registration) => (
    registration.id === registrationId
  ))[0];

  const [urlState, setUrlState] = useRegistrationsUrlState();

  const showMore = () => {
    setUrlState((params) => ({
      ...params,
      registrations: {
        ...params.registrations,
        limit: params.registrations.limit + perPage,
      },
    }), true);
  };

  const [form, setForm] = useState<UpgradesFormState>({
    registrations: registrations.map((registration) => ({
      id: registration.id,
      upgrades: [],
    })),
    stand_alone_upgrades: [] as CreateUpgradeInput[],
    invoice: {
      ...emptyInvoiceDetails,
      country: project.organisation_country,
    },
    payment_method: {
      payment_method: null,
      issuer: null,
    } as Partial<PaymentMethodInput>,
    coupon: null,
    fields: [],
  });

  useEffect(() => {
    setForm((form) => ({
      ...form,
      registrations: registrations.map((registration) => ({
        id: registration.id,
        upgrades: [],
      })),
    }));
  }, [registrations]);

  const { touched, touch } = useTouchState();

  const upgradedRegistrations = form.registrations.filter((value) => value.upgrades.length > 0);

  const participantFields = event.enabled_participant_fields;
  const keyedFields = keyBy(participantFields, 'id');

  // Filter out products that are required, or that have a maximum per order.
  const standAloneProducts = getVisibleProducts(getStandAloneProducts(
    [
      ...registrations.map((registration) => registration.promotion),
      ...summary.assigned_ticket_counts.map(({ promotion }) => promotion),
    ],
    [],
    event.products_for_sale,
  )).filter((product) => product.min_per_order === 0 && product.max_per_order === null);

  const [specifyInvoiceDetails, setSpecifyInvoiceDetails] = useState(false);
  const invoiceDetails = specifyInvoiceDetails ? form.invoice : null;

  const { allowedPaymentMethods, bankTransferEnabled } = usePaymentMethods({
    country: participant.country,
    enabledPaymentMethods: event.payment_methods,
    bankTransferAllowed: !!invoiceDetails?.company_name || !event.require_company_for_bank_transfer,
  });

  const { checkoutSummary, loading: refetching } = useCheckoutSummary({
    event: { id: summary.event.id },
    registrations: {
      update: upgradedRegistrations,
      stand_alone_upgrades: form.stand_alone_upgrades,
    },
    coupon: form.coupon,
    vatId: invoiceDetails?.vat_id,
  });

  const handleTicketUpgradesChange = useCallback(
    (registration: UpdateRegistrationInput) => {
      setForm((form) => addStandAloneFees(setFieldEntries({
        ...form,
        registrations: form.registrations.map((currentRegistration) => (
          registration.id === currentRegistration.id
            ? {
              ...registration,
              upgrades: registration.upgrades,
            }
            : currentRegistration
        )),
      }, participantFields), event));
    },
    [setForm, participantFields, event],
  );

  const handleStandAloneUpgradesChange = useCallback(
    (upgrades: CreateUpgradeInput[]) => {
      setForm((form) => addStandAloneFees(setFieldEntries({
        ...form,
        stand_alone_upgrades: upgrades,
      }, participantFields), event));
    },
    [setForm, participantFields, event],
  );

  const handleStandAloneFieldsChange = (index: number) => (upgrade: CreateUpgradeInput) => {
    setForm((form) => ({
      ...form,
      stand_alone_upgrades: form.stand_alone_upgrades.map(
        (upgradeValue, i) => (i === index ? upgrade : upgradeValue),
      ),
    }));
  };

  const editOrderField = useCallback(
    (update: Update<ParticipantFieldEntryInput>, fieldIndex: number) => {
      setForm((form) => ({
        ...form,
        fields: form.fields.map((field, index) => (
          index === fieldIndex ? update(field) : field
        )),
      }));
    },
    [setForm],
  );

  useEffect(() => {
    setForm((form) => setFieldEntries(form, participantFields));
  }, [participantFields]);

  const [
    updateRegistrations, { data: submitData, error, loading: submitting },
  ] = useMutation(
    UpdateRegistrationsMutation,
    {
      variables: {
        input: {
          participant: {
            id: participant.id,
          },
          event: {
            id: event.id,
          },
          registrations: {
            update: upgradedRegistrations,
            stand_alone_upgrades: form.stand_alone_upgrades,
          },
          payment_method: form.payment_method.payment_method ? form.payment_method as PaymentMethodInput : null,
          invoice: specifyInvoiceDetails ? form.invoice : null,
          coupon: form.coupon,
          fields: form.fields,
        },
      },
      onCompleted: ({ updateRegistrations: { status, redirect_url } }) => {
        if (status === AddOrderStatus.SUCCESS) {
          if (redirect_url) {
            window.location.href = redirect_url;
          }
        }
      },
      onError: () => {
        scrollToTop();
      },
    },
  );

  const checkoutStatus = submitData?.updateRegistrations.status;

  const loading = refetching || submitting;

  const requirePayment = checkoutSummary.amount > 0;

  const couponCode = [...checkoutSummary.tickets, ...checkoutSummary.products]
    .filter((sellable) => sellable.purchase.coupon_code)[0]?.purchase.coupon_code;
  const showCouponInput = (requirePayment || form.coupon || couponCode);

  const editPaymentMethod = (paymentMethod: Partial<PaymentMethodInput>) => {
    setForm((form) => ({
      ...form,
      payment_method: paymentMethod,
    }));
  };

  // Unset the selected payment method when the amount because zero. For example, after entering a coupon code.
  useEffect(() => {
    if (!loading && !requirePayment) {
      editPaymentMethod({
        payment_method: null,
        issuer: null,
      });
    }
  }, [loading, requirePayment]);

  const { errors, valid } = useValidation({
    schema: getSchema(participantFields, requirePayment, allowedPaymentMethods),
    values: {
      ...form,
      invoice: invoiceDetails,
    },
    externalErrors: getServerErrors(error),
  });

  const paymentMethodError = containsKeyPrefix(errors, 'payment_method');
  const invoiceError = containsKeyPrefix(errors, 'invoice');
  const registrationsError = containsKeyPrefix(errors, 'registrations')
    || containsKeyPrefix(errors, 'stand_alone_upgrades')
    || containsKeyPrefix(errors, 'fields');

  const scrollToErrorFields = () => {
    if (registrationsError) {
      scrollTo('UpgradesForm_RegistrationsSection');
    } else if (paymentMethodError || invoiceError) {
      scrollTo('UpgradesForm_PaymentSection');
    } else {
      scrollTo('UpgradesForm');
    }
    Object.keys(errors).forEach((key: string) => touch(key));
  };

  const quantities = calculateQuantities({
    registrations: form.registrations.map((value, index) => ({
      ...value,
      ticket: { id: registrations[index].ticket.id },
      purchase: { promotion: { id: registrations[index].promotion.id } },
    })),
    standAloneUpgrades: form.stand_alone_upgrades,
    products: event.products_for_sale,
  });

  const availability = calculateAvailability({
    quantities,
    products: event.products_for_sale,
  });

  const anyRegistrationsLocked = form.registrations.reduce(
    (locked, registration) => locked || getRegistration(registration.id).locked,
    false,
  );

  if (!event) {
    return <UI.PageLoader />;
  }

  return (
    <UI.Form onSubmit={() => updateRegistrations()} id="UpgradesForm">
      <UI.GridContainer>
        <Breadcrumbs hideHome={impersonating}>
          <UI.Link to={r('Registrations', {
            eventId: event.id,
            projectId: project.id,
            participantId: participant.id,
            token: summary.view_token,
          })}
          >
            {event.title}
          </UI.Link>
          <UI.Strong>{t('order_extras')}</UI.Strong>
        </Breadcrumbs>

        <UI.ServerErrors error={error} sc={{ mx: [3, 4] }} />

        <UI.GridContainer sc={{ gutter: 0.75 }}>
          <UI.Div sc={{ px: [3, 4] }}>
            <SectionTitle>
              {t('extras')}
            </SectionTitle>
          </UI.Div>

          {anyRegistrationsLocked && (
            <UI.Info icon={<Lock />} sc={{ mx: [3, 4] }}>
              {t('registration_locked_info')}
            </UI.Info>
          )}

          <Section dashed spacing="md" id="UpgradesForm_RegistrationsSection">
            {form.registrations.length > 0 && (
              <>
                {form.registrations.map(
                  (registration, index) => getRegistration(registration.id).has_upgrades_available && (
                    <UpgradeRegistrationForm
                      registration={getRegistration(registration.id)}
                      value={registration}
                      quantities={quantities}
                      availability={availability}
                      onChange={handleTicketUpgradesChange}
                      event={event}
                      touch={(key) => touch(`registrations.${index}.${key}`)}
                      touched={get(touched, `registrations.${index}`) as NestedTouchState}
                      errors={filterPrefix(errors, `registrations.${index}`)}
                      key={registration.id}
                    />
                  ),
                )}

                {urlState.registrations.limit < summary.registrations_count && (
                  <NavItem
                    onClick={() => showMore()}
                    title={t('show_more_tickets')}
                    icon={loadingSummary ? <UI.Loader /> : <ChevronDown />}
                    sc={{ background: 'gray.50', color: 'gray.500', fontWeight: 500 }}
                  >
                    {t('show_more_tickets')}
                  </NavItem>
                )}
              </>
            )}

            {standAloneProducts.length > 0 && (
              <UI.GridContainer>
                {form.registrations.length > 0 && (
                  <UI.Legend>
                    {t('miscellaneous')}
                  </UI.Legend>
                )}
                <ProductSelector
                  event={event}
                  products={standAloneProducts}
                  upgrades={form.stand_alone_upgrades}
                  onChange={handleStandAloneUpgradesChange}
                  quantities={quantities}
                  globalQuantities={quantities}
                  availability={availability}
                />
                {form.stand_alone_upgrades.map((upgrade, index) => upgrade.fields.length > 0 && (
                  <UpgradeInfoForm
                    upgrade={upgrade}
                    fields={event.enabled_participant_fields}
                    product={standAloneProducts.filter((product) => (
                      product.promotions_for_sale.filter(({ id }) => id === upgrade.purchase.promotion.id).length > 0
                    ))[0]}
                    onChange={handleStandAloneFieldsChange(index)}
                    onBlur={(key: string) => touch(`stand_alone_upgrades.${index}.${key}`)}
                    errors={filterPrefix(errors, `stand_alone_upgrades.${index}`)}
                    touched={get(touched, `stand_alone_upgrades.${index}`) as NestedTouchState}
                    key={index}
                  />
                ))}
              </UI.GridContainer>
            )}
          </Section>
        </UI.GridContainer>

        {form.fields.length > 0 && (
          <Section spacing="md" title={t('additional_info')}>
            <UI.FormGrid sc={{ gutter: 0.5 }}>
              {form.fields.map((value, index) => keyedFields[value.participant_field.id] && (
                <ParticipantFieldInput
                  value={value}
                  onChange={(newValue) => editOrderField((oldValue) => ({ ...oldValue, ...newValue }), index)}
                  onBlur={() => touch(`fields.${index}`)}
                  touched={!!get(touched, `fields.${index}`)}
                  field={keyedFields[value.participant_field.id]}
                  errors={filterPrefix(errors, `fields.${index}`)}
                  key={value.participant_field.id}
                />
              ))}
            </UI.FormGrid>
          </Section>
        )}

        <Section spacing="md" title={t('payment')} id="UpgradesForm_PaymentSection">
          <UI.FormGrid>
            <UI.Legend>{t('invoice_details')}</UI.Legend>

            <UI.FormGrid sc={{ gutter: 0.5 }}>
              <UI.Checkbox
                checked={specifyInvoiceDetails}
                onChange={() => setSpecifyInvoiceDetails((value) => !value)}
              >
                <UI.Div sc={{ strong: bankTransferEnabled && event.require_company_for_bank_transfer }}>
                  {t('frontend:details_form.place_order_as_company')}
                </UI.Div>
                {bankTransferEnabled && event.require_company_for_bank_transfer && (
                  t('frontend:details_form.required_for_bank_transfer')
                )}
              </UI.Checkbox>

              {specifyInvoiceDetails && (
                <UI.FadeIn>
                  <UI.Div sc={{ background: 'gray.50', padding: 3 }}>
                    <InvoiceDetailsForm
                      value={form.invoice}
                      onChange={(invoice) => setForm((values) => ({
                        ...values,
                        invoice: { ...values.invoice, ...invoice },
                      }))}
                      onBlur={(field) => touch(`invoice.${field}`)}
                      touched={get(touched, 'invoice')}
                      errors={filterPrefix(errors, 'invoice')}
                    />
                  </UI.Div>
                </UI.FadeIn>
              )}
            </UI.FormGrid>

            {(showCouponInput || requirePayment) && (
              <>
                <UI.HR />

                <UI.Legend>
                  {t('payment')}
                </UI.Legend>

                {showCouponInput && (
                  <CouponInput
                    value={form.coupon}
                    onChange={(coupon) => setForm((values) => ({
                      ...values,
                      coupon,
                    }))}
                    onBlur={() => touch('coupon')}
                    checkoutSummary={checkoutSummary}
                    loading={loading}
                    errors={errors}
                    touched={touched}
                  />
                )}

                {requirePayment && (
                  <UI.FadeIn>
                    <UI.InputGroup sc={{ mb: 0, valid: !paymentMethodError, touched: !!touched?.payment_method }}>
                      <UI.InputLabel>
                        {t('common:payments.payment_method')}
                        {' '}
                        <UI.RequiredMark sc={{ valid: !paymentMethodError }} />
                      </UI.InputLabel>
                      <PaymentMethodSelection
                        value={form.payment_method}
                        onChange={(paymentMethod) => editPaymentMethod(paymentMethod)}
                        paymentMethods={allowedPaymentMethods}
                      />
                    </UI.InputGroup>
                  </UI.FadeIn>
                )}
              </>
            )}

            <UI.HR />

            <UI.Legend>
              {t('your_order')}
            </UI.Legend>

            <CheckoutSummary event={event} summary={checkoutSummary} />

            <UI.Div>
              <Trans
                i18nKey="common:terms.terms_notice"
                values={{
                  action: t(requirePayment ? 'pay' : 'complete_upgrade'),
                }}
              >
                <UI.A href={config.termsUrl[locale]} target="_blank" rel="noopener noreferer" />
              </Trans>
            </UI.Div>

            {!valid && (
              <FillRequiredFields onClick={() => scrollToErrorFields()} />
            )}

            <UI.Button
              type="submit"
              sc={{ block: true, brand: 'secondary', loading }}
              disabled={
                !valid || upgradedRegistrations.length + form.stand_alone_upgrades.length === 0 || submitting
              }
            >
              {submitting
                ? <UI.Loader sc={{ brand: 'white' }} />
                : (
                  <>
                    <UI.Icon>
                      <Lock />
                    </UI.Icon>
                    {' '}
                    {t(requirePayment ? 'pay' : 'complete_upgrade')}
                  </>
                )}
            </UI.Button>

            {checkoutStatus === AddOrderStatus.START_PAYMENT_FAILED && (
              <UI.Error>
                <UI.Strong sc={{ block: true }}>
                  {t('common:payments.could_not_start_payment')}
                </UI.Strong>
                {t('common:payments.try_again_in_a_minute')}
              </UI.Error>
            )}
          </UI.FormGrid>
        </Section>
      </UI.GridContainer>
    </UI.Form>
  );
};

const addStandAloneFees = (form: UpgradesFormState, event: Event) => {
  const selectedProductIds = [
    ...form.registrations.reduce((productIds, registration) => [
      ...productIds,
      ...registration.upgrades.map(({ product }) => product.id),
    ], [] as string[]),
    ...form.stand_alone_upgrades.map((upgrade) => upgrade.product.id),
  ];
  const selectedProducts = event.products_for_sale.filter((product) => selectedProductIds.includes(product.id));
  const feeProducts = getStandAloneProducts([], selectedProducts, event.products_for_sale).filter(
    (product) => product.is_ticket_fee,
  );

  // Keep all selected stand-alone upgrades (except fees).
  const standAloneUpgrades = form.stand_alone_upgrades.filter((upgrade) => (
    !event.products_for_sale.filter((product) => product.id === upgrade.product.id)[0]?.is_ticket_fee
  ));

  // Add stand-alone fees.
  const standAloneFees = feeProducts.map((product) => ({
    product: { id: product.id },
    purchase: { promotion: { id: product.promotions_for_sale[0].id } },
    fields: [],
  }));

  return {
    ...form,
    stand_alone_upgrades: [
      ...standAloneUpgrades,
      ...standAloneFees,
    ],
  };
};

/**
 * Goes through the upgrades and creates and prefills participant field entries where necessary:
 */
const setFieldEntries = (form: UpgradesFormState, participantFields: ParticipantField[]) => ({
  ...form,
  registrations: form.registrations.map((value) => ({
    ...value,
    upgrades: value.upgrades.map((upgrade) => ({
      ...upgrade,
      fields: prefillFieldEntries(
        upgrade.fields,
        getPromotionFields(participantFields, upgrade.purchase.promotion.id),
      ),
    })),
  })),
  stand_alone_upgrades: form.stand_alone_upgrades.map((upgrade) => ({
    ...upgrade,
    fields: prefillFieldEntries(
      upgrade.fields,
      getPromotionFields(participantFields, upgrade.purchase.promotion.id),
    ),
  })),
  fields: prefillFieldEntries(
    form.fields,
    getOrderFields(participantFields, [
      ...form.registrations.reduce((promotionIds, registration) => [
        ...promotionIds,
        ...registration.upgrades.map(({ purchase }) => purchase.promotion.id),
      ], [] as string[]),
      ...form.stand_alone_upgrades.map((upgrade) => upgrade.purchase.promotion.id),
    ]),
  ),
});

const getSchema = (
  participantFields: ParticipantField[],
  requirePayment: boolean,
  allowedPaymentMethods: string[],
) => (
  yup.object({
    registrations: yup.array(yup.object({
      upgrades: yup.array(yup.object({
        fields: yup.array(getParticipantFieldEntrySchema(participantFields)),
      })),
    })),
    stand_alone_upgrades: yup.array(yup.object({
      fields: yup.array(getParticipantFieldEntrySchema(participantFields)),
    })),
    fields: yup.array(getParticipantFieldEntrySchema(participantFields)),
    invoice: getInvoiceSchema().nullable(),
    payment_method: yup.object({
      payment_method: (requirePayment
        ? yup.string().ensure().required()
        : yup.string().ensure()
      ).test('invalid', (value) => (
        !value || allowedPaymentMethods.includes(value)
      )),
    }),
  })
);

export default UpgradesForm;
