import { gql } from '__generated__';

export const TeamFieldsFragment = gql(`
  fragment TeamFields on Team {
    id
    title
    members {
      id
      sequence
      ticket {
        id
        title
      }
      full_name
    }
  }
`);

export default gql(`
  query GetTeamDetails($id: ID!) {
    team(id: $id) {
      ...TeamFields
    }
  }
`);
