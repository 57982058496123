import { gql } from '__generated__';

export default gql(`
  mutation EditCurrentUser($input: EditCurrentUserInput!) {
    editCurrentUser(input: $input) {
      id
      first_name
      last_name
      email
      locale
    }
  }
`);
