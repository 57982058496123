import {
  Check,
  Copy,
  ExternalLink,
} from 'react-feather';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Event, Registration } from '../helpers';
import { useCopyLink } from '../../../common/Common/CopyLink';
import UI from '../../../common/UI';
import useLocale from '../../../common/useLocale';

export interface ExchangeInfoProps {
  registration: Registration;
  event: Event;
}

const ExchangeInfo = ({ registration, event }: ExchangeInfoProps) => {
  const { t } = useTranslation();
  const { formatCurrency, locale } = useLocale();

  const upgradesCount = registration.upgrades.filter((upgrade) => !upgrade.product.is_ticket_fee).length;

  const inputRef = useRef<HTMLInputElement>();
  const buttonRef = useRef<HTMLButtonElement>();

  useEffect(() => {
    const input = inputRef.current;

    if (input) {
      const { paddingRight } = input.style;
      input.style.paddingRight = `${Math.max((buttonRef.current?.offsetWidth || 0) + 10, 0)}px`;

      return () => {
        input.style.paddingRight = `${paddingRight}px`;
      };
    }

    return () => {};
  });

  const [copy, copied] = useCopyLink(registration.exchange_code);

  return (
    <UI.FormGrid sc={{ padding: [3, 4] }}>
      <UI.Div>
        <UI.Legend sc={{ fontWeight: 500, mb: 1 }}>
          {t('exchange_ticket')}
        </UI.Legend>
        <UI.Div sc={{ mb: 1 }}>
          {t('exchange_ticket_description')}
        </UI.Div>
        <UI.UL>
          <UI.LI>
            {t('exchange_ticket_fact_amount', { amount: formatCurrency(registration.exchange_amount) })}
          </UI.LI>
          <UI.LI>
            {t('exchange_ticket_fact_one_off', { event: event.title })}
          </UI.LI>
          <UI.LI>
            {t('exchange_ticket_fact_cancelled')}
          </UI.LI>
          <UI.LI>
            {t('exchange_ticket_fact_refunds')}
          </UI.LI>
          {upgradesCount > 0 && (
            <UI.LI>
              {t('exchange_ticket_fact_upgrades')}
            </UI.LI>
          )}
        </UI.UL>
      </UI.Div>
      <UI.InputGroup>
        <UI.InputLabel htmlFor={`CouponCode_${registration.id}`}>
          {t('your_coupon_code')}
        </UI.InputLabel>
        <UI.InputWrapper>
          <UI.Input
            defaultValue={registration.exchange_code}
            readOnly
            onFocus={(event) => event.target.select()}
            ref={inputRef}
            sc={{ color: 'gray.500' }}
            id={`CouponCode_${registration.id}`}
          />
          <UI.Button
            onClick={() => copy()}
            sc={{ blank: true, noWrap: true }}
            ref={buttonRef}
            style={{ borderLeft: '1px solid rgba(0, 0, 0, .075)' }}
          >
            <UI.Icon>
              {copied ? <Check /> : <Copy />}
            </UI.Icon>
            {' '}
            {t('copy')}
          </UI.Button>
        </UI.InputWrapper>
      </UI.InputGroup>
      <UI.AButton
        href={`${event.checkout_url}?coupon=${registration.exchange_code}&locale=${locale}`}
        sc={{ brand: 'secondary' }}
      >
        <UI.Icon>
          <ExternalLink />
        </UI.Icon>
        {' '}
        {t('choose_a_new_ticket')}
      </UI.AButton>
    </UI.FormGrid>
  );
};

export default ExchangeInfo;
