import { AlertTriangle, Lock } from 'react-feather';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PaymentMethodInput } from '__generated__/graphql';

import { Order } from '../../helpers';
import PaymentMethodSelection from '../../../../common/Payments/PaymentMethodSelection';
import PlatformFeeLine from '../../../../common/Purchases/PlatformFeeLine';
import PurchaseLine from '../../../../common/Purchases/PurchaseLine';
import UI from '../../../../common/UI';
import useLocale from '../../../../common/useLocale';
import usePaymentMethods, { PaymentMethod } from '../../../../common/usePaymentMethods';
import useUrlState from '../../../../common/useUrlState';

export interface OrderSummaryProps {
  order: Order;
}

const OrderSummary = ({ order }: OrderSummaryProps) => {
  const { t } = useTranslation();
  const { formatCurrency, formatDate, parseDate } = useLocale();
  const { event } = order;

  const { allowedPaymentMethods } = usePaymentMethods({
    country: order.country,
    enabledPaymentMethods: event.payment_methods,
    bankTransferAllowed: !!order.company_name || !event.require_company_for_bank_transfer,
  });

  const [urlState] = useUrlState<{ orderId?: string; }>({
    parse: (params, { string }) => ({
      ...params,
      orderId: string(params.orderId),
    }),
  });

  const [paymentMethod, setPaymentMethod] = useState<Partial<PaymentMethodInput>>({
    payment_method: null,
    issuer: null,
  });

  const [redirecting, setRedirecting] = useState<boolean>(false);

  const startPayment = () => {
    setRedirecting(true);
    window.location.href = `/p/pay/${order.id}/${order.token}/${paymentMethod.payment_method}${paymentMethod.issuer ? `/${paymentMethod.issuer}` : ''}`;
  };

  const selectedPaymentMethod = paymentMethod.payment_method;
  const bankTransferBlocked = selectedPaymentMethod === PaymentMethod.BankTransfer
    && event.require_company_for_bank_transfer
    && !order.company_name;
  const validPaymentMethod = selectedPaymentMethod && !bankTransferBlocked;

  const invoiceDate = parseDate(order.invoice_date, { timezone: 'UTC', format: 'internal_date' });
  const dueDate = parseDate(order.due_date, { timezone: 'UTC', format: 'internal_date' });

  const passedOnFee = order.paid_passed_on_fee + order.remaining_passed_on_fee;

  return (
    <>
      <UI.GridContainer>
        {order.remaining_amount === 0 && urlState.orderId && (
          <UI.Success>
            <UI.Strong>{t('payment_successful_message')}</UI.Strong>
          </UI.Success>
        )}

        <UI.GridContainer sc={{ gutter: 0.5 }}>
          <UI.GridContainer sc={{ columns: '1fr 2fr', alignVertical: 'center' }}>
            <UI.Div>
              <UI.Legend>{t('order_number')}</UI.Legend>
            </UI.Div>
            <UI.Div>
              {order.invoice_id}
            </UI.Div>
          </UI.GridContainer>
          <UI.GridContainer sc={{ columns: '1fr 2fr', alignVertical: 'center' }}>
            <UI.Div>
              <UI.Legend>{t('date')}</UI.Legend>
            </UI.Div>
            <UI.Div>
              {formatDate(invoiceDate, { format: 'display_compact_date' })}
            </UI.Div>
          </UI.GridContainer>
          {order.due_date !== order.invoice_date && (
            <UI.GridContainer sc={{ columns: '1fr 2fr', alignVertical: 'center' }}>
              <UI.Div>
                <UI.Legend>{t('due_date')}</UI.Legend>
              </UI.Div>
              <UI.Div>
                {formatDate(dueDate, { format: 'display_compact_date' })}
              </UI.Div>
            </UI.GridContainer>
          )}

          {order.credited_amount > 0 && (
            <UI.GridContainer sc={{ columns: '1fr 2fr', alignVertical: 'center' }}>
              <UI.Div>
                <UI.Legend>{t('credited')}</UI.Legend>
              </UI.Div>
              <UI.Div>
                {formatCurrency(-order.credited_amount)}
              </UI.Div>
            </UI.GridContainer>
          )}

          <UI.GridContainer sc={{ columns: '1fr 2fr', alignVertical: 'center' }}>
            <UI.Div>
              <UI.Legend>{t('status')}</UI.Legend>
            </UI.Div>
            <UI.Div>
              {order.remaining_amount === 0 && (
                <UI.Span sc={{ color: 'success' }}>
                  {t('paid')}
                </UI.Span>
              )}
              {order.remaining_amount > 0 && (
                <UI.Strong>
                  <UI.Icon>
                    <AlertTriangle />
                  </UI.Icon>
                  {' '}
                  {t('open')}
                </UI.Strong>
              )}
            </UI.Div>
          </UI.GridContainer>
        </UI.GridContainer>

        {order.remaining_amount > 0 && (
          <>
            <PaymentMethodSelection
              value={paymentMethod}
              onChange={(paymentMethod) => setPaymentMethod(paymentMethod)}
              paymentMethods={allowedPaymentMethods}
            />

            <UI.Button
              sc={{ brand: 'secondary' }}
              disabled={!validPaymentMethod || redirecting}
              onClick={startPayment}
            >
              <UI.Icon>
                <Lock />
              </UI.Icon>
              {' '}
              {t('pay')}
              {' ('}
              {formatCurrency(order.remaining_amount)}
              )
            </UI.Button>
          </>
        )}
      </UI.GridContainer>

      {(order.summary.tickets.length > 0 || order.summary.products.length > 0) && (
        <UI.GridContainer sc={{ gutter: 0.75 }}>
          <UI.Legend>
            {t('purchases')}
          </UI.Legend>
          <UI.GridContainer sc={{ gutter: 0.5 }}>
            {order.summary.tickets.map((ticket, index) => (
              <PurchaseLine ticket={ticket} key={index} />
            ))}
            {order.summary.products.map((product, index) => (
              <PurchaseLine product={product} key={index} />
            ))}
          </UI.GridContainer>
          {passedOnFee > 0 && (
            <>
              <UI.HR />
              <PlatformFeeLine fee={passedOnFee} />
            </>
          )}
          <UI.HR sc={{ borderWidth: 2 }} />
          <UI.GridContainer sc={{ columns: '2fr 1fr' }}>
            <UI.Div sc={{ fontSize: 3, fontWeight: 500 }}>
              {t('total')}
            </UI.Div>
            <UI.Div sc={{ fontSize: 3, strong: true, textAlign: 'right' }}>
              {formatCurrency(order.invoice_amount)}
            </UI.Div>
          </UI.GridContainer>
        </UI.GridContainer>
      )}
    </>
  );
};

export default OrderSummary;
