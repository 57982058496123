import { ArrowDown, Filter, Search, Users, X, XCircle } from 'react-feather';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AssignmentStatus } from '__generated__/graphql';

import { RegistrationSummary } from '../helpers';
import { Section, SectionTitle } from '../../Common/Layout';
import { toggleInList } from '../../../common/helpers';
import { useRegistrationsUrlState } from './helpers';
import RegistrationDetails from './RegistrationDetails';
import UI from '../../../common/UI';
import useDebouncedEffect from '../../../common/useDebouncedEffect';
import useMemoizedValue from '../../../common/useMemoizedValue';

export interface RegistrationsSectionProps {
  summary: RegistrationSummary;
  perPage?: number;
}

const RegistrationsSection = ({ summary, perPage = 10 }: RegistrationsSectionProps) => {
  const { t } = useTranslation();

  const searchRef = useRef<HTMLInputElement>();

  const [urlState, setUrlState] = useRegistrationsUrlState();

  const showMore = () => {
    setUrlState((params) => ({
      ...params,
      registrations: {
        ...params.registrations,
        limit: params.registrations.limit + perPage,
      },
    }), true);
  };

  const showLess = () => {
    setUrlState((params) => ({
      ...params,
      registrations: {
        ...params.registrations,
        limit: null,
      },
    }), true);
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setUrlState((params) => ({
      ...params,
      registrations: {
        ...params.registrations,
        limit: null,
        search: event.target.value || null,
      },
    }), true);
  };

  const resetSearch = () => {
    if (searchRef.current) {
      searchRef.current.value = '';
    }

    setUrlState((params) => ({
      ...params,
      registrations: {
        ...params.registrations,
        limit: null,
        search: null,
      },
    }), true);
  };

  const [showFilters, setShowFilters] = useState(false);

  const toggleShowFilters = (show: boolean) => {
    setShowFilters(show);

    setUrlState((params) => ({
      ...params,
      registrations: {
        ...params.registrations,
        ticket: null,
        team: null,
        assignment_statuses: [],
        limit: null,
      },
    }), true);
  };

  const enableTicketFilter = summary.assigned_ticket_counts.length > 1;
  const enableTeamFilter = summary.teams.length > 0;

  const handleTicketChange = (event:ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;

    setUrlState((params) => ({
      ...params,
      registrations: {
        ...params.registrations,
        ticket: value === 'all' ? null : value,
        limit: null,
      },
    }), true);
  };

  const handleTeamChange = (event:ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;

    setUrlState((params) => ({
      ...params,
      registrations: {
        ...params.registrations,
        team: value === 'all' ? null : value,
        limit: null,
      },
    }), true);
  };

  /**
   * Toggles a filter in a list: if it's not in the list, it's added, otherwise it's removed.
   */
  const toggleListFilter = (
    name: 'assignment_statuses',
    value: string,
  ) => {
    setUrlState((params) => ({
      ...params,
      registrations: {
        ...params.registrations,
        [name]: toggleInList([...urlState.registrations[name] || []], value),
        limit: null,
      },
    }), true);
  };

  const hasAssignmentStatusFilter = urlState.registrations.assignment_statuses.length > 0;
  const hasTicketFilter = !!urlState.registrations.ticket;
  const hasTeamFilter = !!urlState.registrations.team;

  const hasFilters = hasAssignmentStatusFilter || hasTicketFilter || hasTeamFilter;

  useDebouncedEffect(() => {
    if (hasFilters) {
      setShowFilters(true);
    }
  }, 250, [hasFilters]);

  const [loading, setLoading] = useState(false);
  const params = useMemoizedValue(urlState.registrations);

  useEffect(() => {
    setLoading(true);
  }, [params]);

  useEffect(() => {
    setLoading(false);
  }, [summary]);

  const registrations = summary.registrations.slice(0, urlState.registrations.limit);
  const filteredTotal = summary.filtered_registrations_count;

  return (
    <UI.GridContainer sc={{ gutter: 0.75 }} id="TicketsSection">
      <UI.Div sc={{ px: [3, 4] }}>
        <UI.GridContainer sc={{ columns: '1fr fit-content(150px)' }}>
          <UI.Div>
            <SectionTitle
              count={summary.registrations_count > 1 ? summary.filtered_registrations_count : undefined}
              loading={loading}
            >
              {t('your_tickets_title', { count: summary.registrations_count })}
            </SectionTitle>
          </UI.Div>
        </UI.GridContainer>

        {summary.registrations_count > 1 && (
          <UI.Div sc={{ mt: 3 }}>
            <UI.FormGrid sc={{ columns: '1fr fit-content(100px)', gutter: 0.5 }}>
              <UI.InputGroup>
                <UI.InputWrapper>
                  <UI.Span>
                    <UI.Icon>
                      <Search />
                    </UI.Icon>
                  </UI.Span>
                  <UI.DebouncedInput
                    type="text"
                    value={urlState.registrations.search}
                    onChange={handleSearch}
                    placeholder={t('search_placeholder')}
                    delay={500}
                    ref={searchRef}
                    sc={{ px: 5 }}
                  />
                  {urlState.registrations.search && (
                    <UI.Span>
                      <UI.A onClick={() => resetSearch()} sc={{ color: 'gray.400' }}>
                        <UI.Icon>
                          <XCircle />
                        </UI.Icon>
                      </UI.A>
                    </UI.Span>
                  )}
                </UI.InputWrapper>
              </UI.InputGroup>
              <UI.Div>
                <UI.Button
                  onClick={() => toggleShowFilters(!showFilters)}
                  sc={{ outline: true, active: showFilters }}
                >
                  <UI.Icon>
                    {showFilters ? <X /> : <Filter />}
                  </UI.Icon>
                  {' '}
                  {t('filters')}
                </UI.Button>
              </UI.Div>
            </UI.FormGrid>
            <UI.AnimateHeight isVisible={showFilters}>
              {(enableTicketFilter || enableTeamFilter) && (
                <UI.FormGrid
                  sc={{ columns: enableTicketFilter && enableTeamFilter ? '1fr 1fr' : '1fr', gutter: 0.5, mt: 2 }}
                >
                  {enableTicketFilter && (
                    <UI.InputGroup>
                      <UI.InputWrapper>
                        <UI.Span sc={{ muted: !hasTicketFilter }}>
                          <UI.Icon>
                            <UI.Icons.Ticket />
                          </UI.Icon>
                        </UI.Span>
                        <UI.Select
                          value={!hasTicketFilter ? 'all' : urlState.registrations.ticket}
                          onChange={handleTicketChange}
                          sc={{ pl: 5, hasValue: hasTicketFilter }}
                        >
                          <option value="all">
                            {hasTicketFilter ? t('all_tickets') : t('filter_by_ticket')}
                          </option>
                          {summary.assigned_ticket_counts.map(({ ticket }) => (
                            <option value={ticket.id} key={ticket.id}>
                              {ticket.title}
                            </option>
                          ))}
                        </UI.Select>
                      </UI.InputWrapper>
                    </UI.InputGroup>
                  )}
                  {enableTeamFilter && (
                    <UI.InputGroup>
                      <UI.InputWrapper>
                        <UI.Span sc={{ muted: !hasTeamFilter }}>
                          <UI.Icon>
                            <Users />
                          </UI.Icon>
                        </UI.Span>
                        <UI.Select
                          value={urlState.registrations.team || 'all'}
                          onChange={handleTeamChange}
                          sc={{ pl: 5, hasValue: hasTeamFilter }}
                        >
                          <option value="all">
                            {hasTeamFilter ? t('all_teams') : t('filter_by_team')}
                          </option>
                          <option value="no_team">
                            {t('no_team')}
                          </option>
                          {summary.teams.map((team) => (
                            <option value={team.id} key={team.id}>
                              {team.title}
                            </option>
                          ))}
                        </UI.Select>
                      </UI.InputWrapper>
                    </UI.InputGroup>
                  )}
                </UI.FormGrid>
              )}
              <UI.FlexContainer sc={{ py: 1, spaceX: 2, mt: 1 }}>
                <UI.Checkbox
                  checked={urlState.registrations.assignment_statuses.includes(AssignmentStatus.assigned)}
                  onChange={() => toggleListFilter('assignment_statuses', AssignmentStatus.assigned)}
                >
                  {t('assigned')}
                </UI.Checkbox>
                <UI.Checkbox
                  checked={urlState.registrations.assignment_statuses.includes(AssignmentStatus.invited)}
                  onChange={() => toggleListFilter('assignment_statuses', AssignmentStatus.invited)}
                >
                  {t('invited')}
                </UI.Checkbox>
                <UI.Checkbox
                  checked={urlState.registrations.assignment_statuses.includes(AssignmentStatus.unassigned)}
                  onChange={() => toggleListFilter('assignment_statuses', AssignmentStatus.unassigned)}
                >
                  {t('unassigned')}
                </UI.Checkbox>
              </UI.FlexContainer>
            </UI.AnimateHeight>
          </UI.Div>
        )}
      </UI.Div>

      <UI.GridContainer sc={{ gutter: 0.5, loading }}>
        {registrations.map((registration) => (
          <RegistrationDetails
            registration={registration}
            summary={summary}
            key={registration.id}
          />
        ))}

        {registrations.length === 0 && (
          <Section secondary>
            <UI.FlexContainer sc={{ alignItems: 'center' }} style={{ minHeight: 80 }}>
              <UI.Div sc={{ textAlign: 'center', width: 100, color: 'gray.600' }}>
                {t('no_results')}
              </UI.Div>
            </UI.FlexContainer>
          </Section>
        )}

        {filteredTotal > perPage && (
          <UI.GridContainer
            sc={{
              columns: urlState.registrations.limit > perPage ? 'fit-content(150px) 2fr' : '1fr',
              gutter: 0.5,
              px: [3, 4],
            }}
          >
            {urlState.registrations.limit > perPage && (
              <UI.Button
                onClick={() => showLess()}
                sc={{ outline: true, borderRadius: 'xl', size: 'lg', fontSize: 1 }}
              >
                <UI.Icon>
                  <X />
                </UI.Icon>
                {' '}
                {t('hide')}
              </UI.Button>
            )}
            <UI.Button
              onClick={() => showMore()}
              disabled={urlState.registrations.limit >= filteredTotal || loading}
              sc={{ outline: true, borderRadius: 'xl', size: 'lg', fontSize: 1 }}
            >
              <UI.Icon>
                <ArrowDown />
              </UI.Icon>
              {' '}
              {t('show_more')}
            </UI.Button>
          </UI.GridContainer>
        )}
      </UI.GridContainer>
    </UI.GridContainer>
  );
};

export default RegistrationsSection;
