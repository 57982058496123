import { Mail } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { DropdownSection, SectionTitle, useDropdownSectionState } from '../../Common/Layout';
import { Message } from '../helpers';
import UI from '../../../common/UI';
import useLocale from '../../../common/useLocale';

export interface MessagesSectionProps {
  messages: Message[];
}

const MessagesSection = ({ messages }: MessagesSectionProps) => {
  const { t } = useTranslation();
  const { formatDate, parseDate } = useLocale();
  const { isOpen, toggleSection } = useDropdownSectionState();

  return (
    <UI.GridContainer sc={{ gutter: 0.75 }}>
      <UI.Div sc={{ px: [3, 4] }}>
        <SectionTitle count={messages.length}>
          {t('messages')}
        </SectionTitle>
      </UI.Div>

      <UI.GridContainer sc={{ gutter: 0.5 }}>
        {messages.map((message) => (
          <DropdownSection
            open={isOpen()}
            onToggle={() => toggleSection()}
            title={message.subject}
            preview={(
              <>
                <UI.H4>
                  <UI.Icon>
                    <Mail />
                  </UI.Icon>
                  {' '}
                  {message.subject}
                </UI.H4>
                <UI.Div sc={{ muted: true }}>
                  {formatDate(
                    parseDate(message.publication_date, { timezone: 'UTC' }),
                    { format: 'display_date' },
                  )}
                </UI.Div>
              </>
            )}
            body={(
              <UI.Div sc={{ padding: [3, 4] }}>
                <UI.HTML html={message.parsed_content} />
              </UI.Div>
            )}
            key={message.id}
          />
        ))}
      </UI.GridContainer>
    </UI.GridContainer>
  );
};

export default MessagesSection;
