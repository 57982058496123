import useUrlState from '../../../common/useUrlState';

export const useRegistrationsUrlState = () => useUrlState({
  parse: (params, { array, string, integer, object }) => ({
    ...params,
    registrations: object(params.registrations, (registrations) => ({
      limit: integer(registrations.limit, 10),
      search: string(registrations.search),
      ticket: string(registrations.ticket),
      team: string(registrations.team),
      assignment_statuses: array(registrations.assignment_statuses, string),
    })),
    teams: object(params.teams, (teams) => ({
      limit: integer(teams.limit, 5),
      search: string(teams.search),
    })),
    // Other
    orderId: string(params.orderId),
    claimedId: string(params.claimedId),
  }),
});

export default undefined;
