import { gql } from '__generated__';

export default gql(`
  mutation UpdateRegistrations($input: UpdateRegistrationsInput!) {
    updateRegistrations(input: $input) {
      status
      redirect_url
    }
  }
`);
