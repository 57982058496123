import { gql } from '__generated__';

export default gql(`
  query GetSupportaCampaigns($participant: ID!, $event: ID!, $token: String) {
    registrationSummary(participant: $participant, event: $event, token: $token) {
      id
      supporta_campaigns {
        id
        title
        funds_raised
        url
        charity {
          id
          title
          logo_url
        }
      }
    }
  }
`);
