import { gql } from '__generated__';

export default gql(`
  query GetClaimableRegistration($registration_id: ID!, $claim_token: String!, $event_id: ID!) {
    claimableRegistration(registration_id: $registration_id, claim_token: $claim_token) {
      id
      owner
      ticket {
        id
        title
      }
      next_registration {
        id
        required_attributes
        invited_email
        promotion {
          id
          title
        }
        default_charity {
          id
          title
          logo_url
          description
        }
        ticket {
          id
          title
          allow_individuals
          allow_create_team
          allow_join_team
          business
          upcoming_time_slots {
            id
            start_date
            start_time
            title
            multi_date
            is_sold_out
          }
        }
        time_slot {
          id
          start_date
          start_time
          title
          multi_date
          is_sold_out
        }
        team {
          id
          title
        }
        participant_fields {
          ...ParticipantFieldFields
        }
        upgrades {
          id
          resale_amount
          product {
            id
            title
            is_ticket_fee
            donation
            active_product_variants {
              id
              title
              is_sold_out
              position
            }
          }
          promotion {
            id
            title
          }
          product_variant {
            id
            title
            is_sold_out
            position
          }
          participant_fields {
            ...ParticipantFieldFields
          }
        }
      }
    }
    event(id: $event_id) {
      ...EventFields
    }
  }
`);
